import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import CustomFabButton from '../CustomFabButton/CustomFabButton'
import CreditCardsSelection from '../CreditCardsList/CreditCardsList'
import { storeCreditCardPaymentDetails, setPaymentMethod, getUserPaymentDetails } from '../../store/CheckoutFlow/actions'
import { tokenizeCreditCard, initiateAddCreditCard, removeFreedomPayIframe } from '../../store/creditCard/actions'
import styles from './PaymentOptions.css'
const ALLOW_CREDIT_CARD_PAYMENT = true
const CREDIT_CARD_PAYMENT_METHOD_ID = 1
const INVOICE_PAYMENT_METHOD_ID = 5
const PAY_USING_LABEL = 'PAY USING'

const InvoicePaymentMethod = {
  id: INVOICE_PAYMENT_METHOD_ID,
  label: 'Invoice'
}
const CreditCardPaymentMethod = {
  id: CREDIT_CARD_PAYMENT_METHOD_ID,
  label: 'Credit Card'
}

const showBalance = (creditCardBalace, classes) => {
  const creditCardBalaceAmount = creditCardBalace || 0
  const creditCardBalaceAmountClass = parseFloat(creditCardBalaceAmount) === 0
    ? classes.zeroBalance
    : parseFloat(creditCardBalaceAmount) < 0
      ? classes.negativeBalance
      : classes.creditCardBalaceAmount
  return <span className={creditCardBalaceAmountClass}>
    ${parseFloat(creditCardBalaceAmount).toFixed(2)}
  </span>
}
const PaymentMethodOptions = ({
  userPaymentDetails: {
    credit_card_details: allCreditCards = [],
    companies_suites_details: companiesSuites = []
  } = {},
  usedCreditCards = [],
  storeCreditCardPaymentDetails,
  classes,
  paymentMethod,
  setPaymentMethod,
  orderTotal,
  creditCardBalanceAmount,
  companiesSuiteId,
  orderId,
  isSesUser,
  addCardErr,
  freedompayIframe,
  tokenizeCreditCard,
  initiateAddCreditCard,
  removeFreedomPayIframe,
  showFormToNameCreditCard,
  clearAddCreditCardError,
  userId,
  suiteId
}) => {
  const invoicePaymentMethodAvailable = () => {
    const targetSuite = companiesSuites.find(companiesSuite => companiesSuite.companies_suite_id === companiesSuiteId)
    return targetSuite && targetSuite.can_use_invoice
  }

  const formattedCreditCards = allCreditCards.map(card => {
    return {
      ...card,
      cardName: card.card_name + ' - ' + card.last_four_digits
    }
  })

  return (
    <div className={classes.paymentMethodOptionsContainer}>
      <div className={classes.sectionTitle}>
        {PAY_USING_LABEL}
      </div>
      {
        paymentMethod === CREDIT_CARD_PAYMENT_METHOD_ID && <div className={classes.balanceTextTitle}>
          BALANCE
        </div>
      }
      <div className={classes.paymentMethodButtonsContainer}>
        {
          invoicePaymentMethodAvailable() &&
          <CustomFabButton
            key={INVOICE_PAYMENT_METHOD_ID}
            variant="extended"
            color="default"
            styleClasses={`${classes.genericFabPill} ${INVOICE_PAYMENT_METHOD_ID === paymentMethod ? classes.activeFabPill : ''}`}
            disableRipple
            disableFocusRipple
            label={InvoicePaymentMethod.label}
            value={INVOICE_PAYMENT_METHOD_ID}
            clickHandler={setPaymentMethod}
          />
        }

        {
          ALLOW_CREDIT_CARD_PAYMENT &&
          <CustomFabButton
            key={CREDIT_CARD_PAYMENT_METHOD_ID}
            variant="extended"
            color="default"
            styleClasses={`${classes.genericFabPill} ${CREDIT_CARD_PAYMENT_METHOD_ID === paymentMethod ? classes.activeFabPill : ''}`}
            disableRipple
            disableFocusRipple
            label={CreditCardPaymentMethod.label}
            value={CREDIT_CARD_PAYMENT_METHOD_ID}
            clickHandler={setPaymentMethod}
          />
        }
        {
          paymentMethod === CREDIT_CARD_PAYMENT_METHOD_ID &&
          ALLOW_CREDIT_CARD_PAYMENT &&
          showBalance(creditCardBalanceAmount, classes)
        }
        {
          paymentMethod === CREDIT_CARD_PAYMENT_METHOD_ID &&
          ALLOW_CREDIT_CARD_PAYMENT &&
          <CreditCardsSelection
            addCardErr={addCardErr}
            freedompayIframe={freedompayIframe}
            showFormToNameCreditCard={showFormToNameCreditCard}
            clearAddCreditCardError={clearAddCreditCardError}
            availableCreditCards={formattedCreditCards}
            storeCreditCardPaymentDetails={storeCreditCardPaymentDetails}
            usedCreditCards={usedCreditCards}
            orderId={orderId}
            tokenizeCreditCard={tokenizeCreditCard}
            initiateAddCreditCard={initiateAddCreditCard}
            removeFreedomPayIframe={removeFreedomPayIframe}
            isSesUser={isSesUser}
            getUserPaymentDetails={getUserPaymentDetails}
            userId={userId}
            suiteId={suiteId}
          />
        }
      </div>
    </div>
  )
}

PaymentMethodOptions.propTypes = {
  classes: PropTypes.object,
  setPaymentMethod: PropTypes.func,
  paymentMethod: PropTypes.number,
  orderTotal: PropTypes.number,
  orderId: PropTypes.number,
  userPaymentDetails: PropTypes.object,
  usedCreditCards: PropTypes.array,
  storeCreditCardPaymentDetails: PropTypes.func,
  creditCardBalanceAmount: PropTypes.string,
  companiesSuiteId: PropTypes.number,
  isSesUser: PropTypes.bool,
  addCardErr: PropTypes.object,
  tokenizeCreditCard: PropTypes.func,
  initiateAddCreditCard: PropTypes.func.isRequired,
  removeFreedomPayIframe: PropTypes.func,
  clearAddCreditCardError: PropTypes.func,
  freedompayIframe: PropTypes.string,
  showFormToNameCreditCard: PropTypes.bool,
  userId: PropTypes.string,
  suiteId: PropTypes.string
}

const mapStateToProps = (state) => {
  const { checkoutData, creditCardsReducers } = state
  const { freedompayIframe, showFormToNameCreditCard, addCardErr, clearAddCreditCardError } = creditCardsReducers
  return {
    addCardErr,
    freedompayIframe,
    showFormToNameCreditCard,
    clearAddCreditCardError,
    userPaymentDetails: checkoutData.userPaymentDetails,
    paymentMethod: checkoutData.paymentMethod,
    usedCreditCards: checkoutData.usedCreditCards,
    creditCardBalanceAmount: checkoutData.creditCardBalanceAmount,
    companiesSuiteId: checkoutData.companiesSuiteId,
    orderId: checkoutData.orderId
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    storeCreditCardPaymentDetails: (paymentDetails) => {
      dispatch(storeCreditCardPaymentDetails(paymentDetails))
    },
    setPaymentMethod: (paymentMethod) => {
      dispatch(setPaymentMethod(paymentMethod))
    },
    getUserPaymentDetails: (userId, suiteId) => {
      dispatch(getUserPaymentDetails(userId, suiteId))
    },
    initiateAddCreditCard,
    removeFreedomPayIframe,
    tokenizeCreditCard
  }
}

const PaymentMethodOptionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethodOptions)

export default withStyles(styles)(PaymentMethodOptionsContainer)
