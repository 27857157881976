import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { history } from '../../store/store'
import {
  fetchCompanies,
  fetchSuites,
  fetchUsers,
  fetchEvents,
  resetNewOrderData
} from '../../store/companies/actions'
import {
  getEventDetailsWithOrderWindow,
  getUserPaymentDetails,
  getSuiteDetails,
  storeMenuCatalogPrerequisites
} from '../../store/CheckoutFlow/actions'

import Dialog from '../../elements/Dialog/Dialog'
import { verifyToken } from '../../utils/verifyToken'
import { reportGoogleAnalytics } from '../../utils/googleAnalytics'
import CreateOrderDialogContent from './CreateOrderDialogContent'
import CreateOrderDialogActions from './CreateOrderDialogActions'
import CreateOrderDialogTitle from './CreateOrderDialogTitle'
import { styles } from './CreateOrder.css'

const getTitle = () => {
  return <CreateOrderDialogTitle title={'CREATE AN ORDER'} />
}

const getActions = (handleCancel, handleContinue) => {
  return <CreateOrderDialogActions handleCancel={handleCancel} handleContinue={handleContinue} />
}

const getContent = (companiesList, companyId, handleCompanySelection, suites, suiteId, handleSuiteSelection, users, userId, handleUserSelection, menuTypes, menuTypeId, handleMenuTypeSelection, events, eventId, handleEventSelection, eventTypes, filterEvents) => {
  return <CreateOrderDialogContent
    companiesList={companiesList}
    companyId={companyId}
    events={events}
    eventId={eventId}
    eventTypes={eventTypes}
    filterEvents={filterEvents}
    handleSuiteSelection={handleSuiteSelection}
    handleUserSelection={handleUserSelection}
    handleMenuTypeSelection={handleMenuTypeSelection}
    handleEventSelection={handleEventSelection}
    handleCompanySelection={handleCompanySelection}
    menuTypes={menuTypes}
    menuTypeId={menuTypeId}
    suiteId={suiteId}
    suites={suites}
    users={users}
    userId={userId}
  />
}
const CreateOrder = ({
  classes,
  fetchSuites,
  fetchUsers,
  getEventDetailsWithOrderWindow,
  getUserPaymentDetails,
  getSuiteDetails,
  storeMenuCatalogPrerequisites,
  fetchEvents,
  companiesList,
  menuTypes,
  suites = [],
  users = [],
  openDialog,
  events = [],
  eventTypes,
  handleDialogClose,
  resetNewOrderData
}) => {
  verifyToken()
  const [companyId, setCompanyId] = useState('')
  const [eventId, setEventId] = useState('')
  const [suiteId, setSuiteId] = useState('')
  const [userId, setUserId] = useState('')
  const [menuTypeId, setMenuTypeId] = useState('')
  const [companiesSuiteId, setCompaniesSuiteId] = useState('')

  function getAnalyticsDetails (eventId, suiteId, companyId) {
    const event = events.filter((e) => e.id === eventId)
    const suite = suites.filter((s) => s.id === suiteId)
    const company = companiesList.filter((c) => c.id === companyId)
    return {
      event: event[0]?.web_name,
      suite: suite[0]?.name,
      company: company[0]?.name
    }
  }

  function handleContinue () {
    if (companyId && eventId && suiteId && userId && menuTypeId) {
      getEventDetailsWithOrderWindow(eventId, menuTypeId)
      getUserPaymentDetails(userId, suiteId)
      getSuiteDetails(suiteId)
      storeMenuCatalogPrerequisites({ companiesSuiteId, companyId, eventId, suiteId, userId, menuTypeId })
      const { event, suite, company } = getAnalyticsDetails(eventId, suiteId, companyId)
      reportGoogleAnalytics('order_creation', {
        company_selection: company,
        suite_selection: suite,
        event_selection: event
      })
      history.push(`/orders/new?companyId=${companyId}&eventId=${eventId}&suiteId=${suiteId}&userId=${userId}&menuTypeId=${menuTypeId}`)
    }
  }

  function handleCompanySelection (companyId) {
    setCompanyId(companyId)
    setSuiteId('')
    setUserId('')
    setEventId('')
    fetchSuites(companyId)
  }

  function handleSuiteSelection (companyId, suiteId) {
    setSuiteId(suiteId)
    const selectedSuite = suites.find(suite => suite.id === suiteId)
    setCompaniesSuiteId(selectedSuite.companiesSuiteId)
    fetchUsers(companyId, suiteId)
  }

  function handleUserSelection (userId) {
    setUserId(userId)
  }

  function handleEventSelection (eventId) {
    setEventId(+eventId)
  }

  function handleMenuTypeSelection (menuTypeId) {
    setMenuTypeId(menuTypeId)
  }

  function onDialogClose () {
    resetNewOrderData()
    handleDialogClose()
  }

  function filterEvents (filters) {
    setEventId('')
    if (companyId && suiteId && userId && menuTypeId) {
      fetchEvents(companyId, suiteId, menuTypeId, filters)
    }
  }

  const dialogTitle = getTitle()
  const dialogActions = getActions(
    onDialogClose,
    handleContinue
  )
  const dialogContent = getContent(
    companiesList,
    companyId,
    handleCompanySelection,
    suites,
    suiteId,
    handleSuiteSelection,
    users,
    userId,
    handleUserSelection,
    menuTypes,
    menuTypeId,
    handleMenuTypeSelection,
    events,
    eventId,
    handleEventSelection,
    eventTypes,
    filterEvents
  )

  useEffect(() => {
    if (companyId && suiteId && userId && menuTypeId) {
      setEventId('')
      fetchEvents(companyId, suiteId, menuTypeId)
    }
  }, [userId, menuTypeId, companyId, suiteId, fetchEvents])

  return (
    <div>
      <Dialog
        openDialog={openDialog}
        dialogTitle={dialogTitle}
        dialogActions={dialogActions}
        dialogContent={dialogContent}
        handleDialogClose={onDialogClose}
        classes={classes}
      />
    </div>
  )
}

CreateOrder.propTypes = {
  classes: PropTypes.object,
  companiesList: PropTypes.array,
  events: PropTypes.array,
  eventTypes: PropTypes.array,
  fetchSuites: PropTypes.func,
  fetchUsers: PropTypes.func,
  fetchEvents: PropTypes.func,
  getEventDetailsWithOrderWindow: PropTypes.func,
  getUserPaymentDetails: PropTypes.func,
  getSuiteDetails: PropTypes.func,
  menuTypes: PropTypes.array,
  openDialog: PropTypes.bool,
  handleDialogClose: PropTypes.func,
  resetNewOrderData: PropTypes.func,
  suites: PropTypes.array,
  storeMenuCatalogPrerequisites: PropTypes.func,
  users: PropTypes.array
}

const mapStateToProps = (state, ownProps) => {
  return {
    companiesList: state.staticData.companies,
    eventTypes: state.staticData.event_categories,
    events: state.companiesData.companiesSuitesEvents || [],
    menuTypes: state.staticData.menu_types,
    suites: state.companiesData.suites || [],
    users: state.companiesData.companiesSuitesUsers || []
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchCompanies: () => {
      dispatch(fetchCompanies({}, 0, 999, {}))
    },
    fetchSuites: (companyId) => {
      dispatch(fetchSuites(companyId))
    },
    fetchUsers: (companyId, suiteId) => {
      dispatch(fetchUsers(companyId, suiteId))
    },
    fetchEvents: (companyId, suiteId, menuTypeId, filters) => {
      dispatch(fetchEvents(companyId, suiteId, menuTypeId, filters))
    },
    resetNewOrderData: () => {
      dispatch(resetNewOrderData())
    },
    storeMenuCatalogPrerequisites: (companyId, eventId, suiteId, userId, menuTypeId) => {
      dispatch(storeMenuCatalogPrerequisites(companyId, eventId, suiteId, userId, menuTypeId))
    },
    getEventDetailsWithOrderWindow: (eventId, menuTypeId) => {
      dispatch(getEventDetailsWithOrderWindow(eventId, menuTypeId))
    },
    getUserPaymentDetails: (userId, suiteId) => {
      dispatch(getUserPaymentDetails(userId, suiteId))
    },
    getSuiteDetails: (suiteId) => {
      dispatch(getSuiteDetails(suiteId))
    }
  }
}

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrder))
