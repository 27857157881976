import moment from 'moment'
import {
  CLEAR_UPDATE_MESSAGE,
  CLEAR_MENU_CATALOG,
  CLEAR_EVENT_DETAILS,
  COMPANY_DETAILS_BY_ID,
  COMPANY_UPDATED,
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUITES,
  FETCH_COMPANIES_SUITES_USERS,
  FETCH_COMPANIES_SUITES_EVENTS,
  FETCH_COMPANIES_BY_ID_TO_EDIT,
  FETCH_EVENTS_FOR_SUITES_IN_COMPANY,
  RESET_NEW_ORDER_DATA,
  RESET_CHECKOUT_DATA,
  UPDATE_COMPANY_SUITE_EVENT
} from '../actionTypes'
import store, { history } from '../store'
import { logoutUser } from '../authentication/actions'
import { companyEndpoints } from '../../constants/apiConfig'
import { cleanUpFilterObject } from '../helpers/cleanupFilters'
import { sortParamsMapping } from '../../constants/companies'
import { getItemsList } from '../helpers/apiClient'

const prepareCompaniesListQuery = (filters, page, limit, sort) => {
  const { query } = cleanUpFilterObject(filters)
  const { sortBy = '', order = '' } = sort
  return {
    filters: {
      key: 'name',
      value: query
    },
    pagination: {
      page: page + 1,
      limit
    },
    sort: {
      order_by: sortParamsMapping[sortBy],
      order
    }
  }
}

export const fetchCompanies = (filters = {}, page = 0, limit = 10, sortParams = {}) => dispatch => {
  const { method, url } = companyEndpoints.list
  const data = prepareCompaniesListQuery(filters, page, limit, sortParams)
  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      const { companies: items, meta } = data
      dispatch({
        type: FETCH_COMPANIES,
        payload: { items, meta, page, limit, filters, sortParams }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const fetchSuites = (companyId) => dispatch => {
  const { method, url } = companyEndpoints.getSuites
  const urlWithId = url.replace(':companyId', companyId)
  getItemsList(method, urlWithId)
    .then(res => {
      const { data = {} } = res
      const { suites } = data
      dispatch({
        type: FETCH_COMPANIES_SUITES,
        payload: { suites }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const fetchUsers = (companyId, suiteId) => dispatch => {
  const { method, url } = companyEndpoints.getUsers
  const urlWithId = url.replace(':companyId', companyId).replace(':suiteId', suiteId)
  getItemsList(method, urlWithId)
    .then(res => {
      const { data = {} } = res
      const { users } = data
      dispatch({
        type: FETCH_COMPANIES_SUITES_USERS,
        payload: { users }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const fetchEvents = (companyId, suiteId, menuTypeId, filters = {}) => dispatch => {
  const { method, url } = companyEndpoints.getEvents
  const { startDate = '', endDate = '', eventType, query } = filters
  const urlWithId = url.replace(':companyId', companyId).replace(':suiteId', suiteId).replace(':menuTypeId', menuTypeId)
  getItemsList(
    method,
    urlWithId,
    {
      filters: {
        date: {
          start_date: startDate.length > 0 ? moment(startDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : '',
          end_date: endDate.length > 0 ? moment(endDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''
        },
        category: eventType,
        query
      },
      menu_type_id: menuTypeId
    })
    .then(res => {
      const { data = {} } = res
      const { assigned_events: events } = data
      dispatch({
        type: FETCH_COMPANIES_SUITES_EVENTS,
        payload: { events }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const resetCompaniesSuiteEvents = () => dispatch => {
  dispatch({
    type: FETCH_COMPANIES_SUITES_EVENTS,
    payload: { events: [] }
  })
}

export const fetchEventsForCompanySuite = (suiteId, companyId, user, filters = {}) => dispatch => {
  const { method, url } = companyEndpoints.getEventsForSuite

  const { category, dateFrom: startDate = '', dateTo: endDate = '', query } = filters
  const urlWithId = url.replace(':companyId', companyId).replace(':suiteId', suiteId)

  getItemsList(
    method,
    urlWithId,
    {
      filters: {
        date: {
          start_date: startDate && startDate.length > 0 ? moment(startDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : '',
          end_date: endDate && endDate.length > 0 ? moment(endDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''
        },
        category,
        query
      },
      user: user || {}
    })
    .then(res => {
      const { data = {} } = res
      const { events: {
        available_events: availableEvents,
        assigned_events: assignedEvents
      } } = data
      dispatch({
        type: FETCH_EVENTS_FOR_SUITES_IN_COMPANY,
        payload: {
          availableEvents,
          assignedEvents
        }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const fetchCompanyToEdit = (companyId) => dispatch => {
  const { method, url } = companyEndpoints.fetchCompanyToEdit
  const urlWithId = url.replace(':companyId', companyId)
  getItemsList(method, urlWithId)
    .then(res => {
      const { data = {} } = res
      const { company } = data
      dispatch({
        type: FETCH_COMPANIES_BY_ID_TO_EDIT,
        payload: {
          ...company,
          message: ''
        }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const updateCompanySuiteEvent = (suiteId, companyId, assignments, user) => dispatch => {
  const { method, url } = companyEndpoints.updateCompanySuiteEvents
  const urlWithId = url.replace(':companyId', companyId).replace(':suiteId', suiteId)
  const assignedEvents = assignments.assignedEvents.reduce((events, eventObj) => {
    events.push(eventObj.id)
    return events
  }, [])
  const availableEvents = assignments.availableEvents.reduce((events, eventObj) => {
    events.push(eventObj.id)
    return events
  }, [])

  getItemsList(method, urlWithId, {
    company_suite: {
      user: user,
      events: {
        assign: assignedEvents,
        unassign: availableEvents
      }
    } })
    .then(res => {
      const { data } = res
      const { message } = data
      dispatch({
        type: UPDATE_COMPANY_SUITE_EVENT,
        payload: message
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const updateCompanyById = (companyToUpdate) => dispatch => {
  const { method, url } = companyEndpoints.updateCompany
  const urlWithId = url.replace(':companyId', companyToUpdate.id)
  const data = new FormData()
  data.append('company[name]', companyToUpdate.name)
  data.append('company[address_attributes][id]', companyToUpdate.address.id)
  data.append('company[address_attributes][street_address]', companyToUpdate.address.streetAddress || '')
  data.append('company[address_attributes][city]', companyToUpdate.address.city)
  data.append('company[address_attributes][state]', companyToUpdate.address.state)
  data.append('company[address_attributes][zip]', isNaN(companyToUpdate.address.zip) ? '' : companyToUpdate.address.zip)
  data.append('company[companies_suites_attributes]', JSON.stringify(companyToUpdate.suites.map(suite => {
    return {
      ...((suite.id) ? { id: suite.id } : null),
      ...((suite.company_suites_share_type_id) ? { company_suites_share_type_id: suite.company_suites_share_type_id } : null),
      ...((suite.active_since) ? { active_since: suite.active_since } : null),
      suite_id: String(suite.suite_id),
      suite_share_id: String(suite.suite_share_id),
      is_active: suite.is_active || true
    }
  })))

  getItemsList(method, urlWithId, data)
    .then(res => {
      const { data = { } } = res
      const { message, success } = data
      dispatch({
        type: COMPANY_UPDATED,
        payload: {
          message,
          success
        }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const deleteCompanySuite = (companySuiteId, shareTypeId) => dispatch => {
  const { method, url } = companyEndpoints.deleteCompanySuite
  const urlWithId = url.replace(':companySuiteId', companySuiteId).replace(':shareTypeId', shareTypeId)
  getItemsList(method, urlWithId)
    .then(res => {
      const { data = {} } = res

      const { success, message } = data
      const {
        companyDetailsToEdit
      } = store.getState().companiesData
      const suites = companyDetailsToEdit.suites.filter(suite => {
        return suite.id !== companySuiteId
      })
      let company
      if (success) {
        company = {
          ...companyDetailsToEdit,
          ...{ suites },
          message
        }
      } else {
        company = {
          ...companyDetailsToEdit,
          message
        }
      }
      dispatch({
        type: FETCH_COMPANIES_BY_ID_TO_EDIT,
        payload: company
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const getCompanyDetailsById = (companyId) => dispatch => {
  const { method, url } = companyEndpoints.byId(companyId)
  getItemsList(method, url)
    .then(res => {
      const { data = { } } = res
      dispatch({
        type: COMPANY_DETAILS_BY_ID,
        payload: data
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const resetNewOrderData = () => dispatch => {
  dispatch({
    type: RESET_CHECKOUT_DATA,
    payload: {}
  })
  dispatch({
    type: RESET_NEW_ORDER_DATA,
    payload: {}
  })
  dispatch({
    type: CLEAR_MENU_CATALOG,
    payload: {}
  })
  dispatch({
    type: CLEAR_EVENT_DETAILS,
    payload: {}
  })
}

export const clearUpdateMessage = () => async dispatch => {
  dispatch({
    type: CLEAR_UPDATE_MESSAGE,
    payload: {}
  })
}
