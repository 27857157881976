import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import idx from 'idx'
import OrderBasicDetails from '../../elements/OrderBasicDetails/OrderBasicDetails'
import OrderItemsListHeader from '../../elements/OrderItemsListHeader/OrderItemsListHeader'
import OrderItemsList from '../../elements/OrderItemsList/OrderItemsList'
import InSuiteActionOptions from '../../elements/InSuiteActionOptions/InSuiteActionOptions'
import BillSummaryLineItem from '../../elements/BillSummaryLineItem/BillSummaryLineItem'
import { history } from '../../store/store'
import CopyOrder from '../../Dialogs/CopyOrder/CopyOrder'
import { resetPaymentOptions, getEventDetailsWithOrderWindow } from '../../store/CheckoutFlow/actions'
import Confirmation from '../../Dialogs/Confirmation/Confirmation'
import QuickNotice from '../../elements/QuickNotice/QuickNotice'
import { trueRounder, addZeroToOrderPrice } from '../../utils/formatters'

import {
  paymentSummaryLabels,
  paymentFieldsToExclude
} from '../../constants/OrderDetails'
import {
  DEFAULT_CURRENT_PAGE
} from '../../constants/orders'

import {
  fetchOrderById,
  fetchCopyOrderData,
  cancelOrderById,
  resetOrderCancellationNotice
} from '../../store/orders/actions'
import {
  ANALYTICS_ACTION_LOCATION_MODAL,
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_ACTION_CANCEL_ORDER_CONFIRM,
  ANALYTICS_ACTION_CANCEL_ORDER,
  ANALYTICS_ACTION_TYPE_ORDER_CANCEL_CONFIRM,
  ANALYTICS_PAGE_TYPE_CLIENT_ORDERS,
  ANALYTICS_ACTION_EDIT_ORDER,
  ANALYTICS_ACTION_TYPE_ORDER_EDIT,
  ANALYTICS_ACTION_COPY_ORDER,
  ANALYTICS_ACTION_TYPE_ORDER_COPY,
  ANALYTICS_ACTION_CANCEL_ORDER_CLOSE,
  ANALYTICS_ACTION_LOCATION_BODY,
  ANALYTICS_ACTION_TYPE_ORDER_COPY_CANCEL,
  ANALYTICS_ACTION_TYPE_ORDER_COPY_CONFIRM,
  ANALYTICS_ACTION_TYPE_ORDER_CANCEL,
  ANALYTICS_ACTION_TYPE_ORDER_CANCEL_CLOSE
} from '../../constants/analytics'
import {
  reportAnalytics
} from '../../utils/analytics'
import styles from './OrderDetails.css'
const CancelOrderConfirmationMessage = 'Your changes will be permanent. Please confirm you want to cancel the order.'

const {
  subtotalLabel,
  taxLabel,
  gratuityLabel,
  tipFromTerminalLabel,
  totalLabel,
  toBePaidByInvoiceLabel
} = paymentSummaryLabels

const getPaymentDetails = (checkoutOptionsSummary) => {
  const { paymentStatus, paymentDetails } = checkoutOptionsSummary
  const { credit_cards: creditCards = [], invoice } = paymentDetails
  const paymentDetailsKeys = Object.keys(paymentDetails).filter(key => !paymentFieldsToExclude.includes(key))
  let paymentsSummary = []
  if (creditCards && creditCards.length > 0) {
    const creditCardsSummary = creditCards.map(creditCard => {
      const cardName = creditCard.card_name || ''
      const cardLastFourDigits = creditCard.last_four_digits || ''
      const labelPrefix = paymentStatus === 3 ? 'Paid By' : 'To Be Paid By'
      const label = creditCard.is_primary ? 'Primary Credit Card' : 'Credit Card'
      const creditCardAmount = addZeroToOrderPrice(trueRounder(creditCard.amount, 2))
      return <BillSummaryLineItem key={creditCard.id} label={`${labelPrefix} ${label} ${cardName} - ${cardLastFourDigits}`} value2={`$${creditCardAmount}`} paymentLineItem />
    })
    paymentsSummary = [...creditCardsSummary]
  }
  if (invoice && invoice.amount) {
    paymentsSummary.push(<BillSummaryLineItem label={toBePaidByInvoiceLabel} value2={`$${addZeroToOrderPrice(trueRounder(invoice.amount, 2))}`} paymentLineItem />)
  }

  paymentDetailsKeys.forEach(key => {
    const fieldValue = paymentDetails[key].amount
    const fieldKey = key.replace(/_/g, ' ').toLowerCase().replace(/\w{3,}/g, (match) => match.replace(/\w/, (m) => m.toUpperCase()))
    paymentsSummary.push(<BillSummaryLineItem key={fieldKey} label={`Paid By ${fieldKey} (IG)`} value2={`$${addZeroToOrderPrice(trueRounder(fieldValue, 2))}`} paymentLineItem />)
  })
  return paymentsSummary
}

const OrderDetail = ({
  actionBarData = {},
  basicOrderDetails = {},
  classes,
  fetchOrder,
  route,
  order,
  orderSummary = {},
  orderItems = {},
  checkoutOptionsSummary = {},
  fetchCopyOrderData,
  resetPaymentOptions,
  getEventDetailsWithOrderWindow,
  orderCancellationStatus,
  orderCancellationMessage,
  cancelOrderById,
  resetOrderCancellationNotice
}) => {
  const [openCopyOrderDialog, setOpenCopyOrderDialog] = useState(false)
  const [openCancelOrderConfirmationDialog, setOpenCancelOrderConfirmationDialog] = useState(false)
  const orderId = idx(route, _ => _.match.params.id)

  useEffect(props => {
    fetchOrder(orderId)
  }, [orderId, fetchOrder])

  useEffect(() => {
    if (orderCancellationStatus) {
      setTimeout(() => {
        resetOrderCancellationNotice()
      }, 5000)
    }
  }, [orderCancellationStatus, resetOrderCancellationNotice])

  const {
    suite,
    companyName,
    eventName,
    eventDate,
    createdDate,
    total,
    menuTypeName,
    paymentMethodName,
    paymentStatusName,
    submittedBy
  } = basicOrderDetails

  const {
    tipFromTerminal = '0.00'
  } = orderSummary

  const handleEditOrder = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_EDIT_ORDER,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionType: ANALYTICS_ACTION_TYPE_ORDER_EDIT,
        actionOrderId: order.id
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ORDERS
    })
    history.push(`/orders/${orderId}/edit`)
  }
  const copyOrder = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_COPY_ORDER,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionType: ANALYTICS_ACTION_TYPE_ORDER_COPY,
        actionOrderId: order.id
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ORDERS
    })
    resetPaymentOptions()
    setOpenCopyOrderDialog(true)
  }

  const closeCopyOrderDialog = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_COPY_ORDER,
        actionLocation: ANALYTICS_ACTION_LOCATION_MODAL,
        actionType: ANALYTICS_ACTION_TYPE_ORDER_COPY_CANCEL,
        actionOrderId: order.id
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ORDERS
    })
    setOpenCopyOrderDialog(false)
  }

  const handleCopyOrder = (order, eventId) => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_COPY_ORDER,
        actionLocation: ANALYTICS_ACTION_LOCATION_MODAL,
        actionType: ANALYTICS_ACTION_TYPE_ORDER_COPY_CONFIRM,
        actionOrderId: order.id
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ORDERS
    })
    getEventDetailsWithOrderWindow(eventId, order.menuTypeId)
    fetchCopyOrderData(order.id, eventId)
    history.push('/checkout')
  }

  const handleCancelOrder = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_CANCEL_ORDER,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionType: ANALYTICS_ACTION_TYPE_ORDER_CANCEL,
        actionOrderId: order.id
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ORDERS
    })
    setOpenCancelOrderConfirmationDialog(true)
  }

  const cancelOrder = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_CANCEL_ORDER_CONFIRM,
        actionLocation: ANALYTICS_ACTION_LOCATION_MODAL,
        actionType: ANALYTICS_ACTION_TYPE_ORDER_CANCEL_CONFIRM,
        actionOrderId: order.id
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ORDERS
    })
    cancelOrderById(order.id, DEFAULT_CURRENT_PAGE)
    setOpenCancelOrderConfirmationDialog(false)
    fetchOrder(orderId)
  }

  const handleCancelConfirmationDialogClose = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_CANCEL_ORDER_CLOSE,
        actionLocation: ANALYTICS_ACTION_LOCATION_MODAL,
        actionType: ANALYTICS_ACTION_TYPE_ORDER_CANCEL_CLOSE,
        actionOrderId: order.id
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ORDERS
    })
    setOpenCancelOrderConfirmationDialog(false)
  }

  return (
    <React.Fragment>
      { orderCancellationStatus && <QuickNotice message={orderCancellationMessage} /> }
      <div id="order-details-container" className={classes.orderDetailsContainer}>
        <div className={classes.orderDetailsHeaderContainer}>
          <div className={classes.orderNumber}>
            {`Order#${actionBarData.orderId}`}
          </div>
          <div id="order-details-actions" className={classes.orderActionButtonsContainer}>
            <Button className={classes.orderActionButton} disabled={!actionBarData.isEditableByCurrentUser} onClick={handleCancelOrder}> Cancel </Button>
            <Button className={classes.orderActionButton} disabled={!actionBarData.isEditableByCurrentUser} onClick={handleEditOrder}> Edit </Button>
            <Button className={classes.orderActionButton} disabled={!actionBarData.isEditableByCurrentSESUser} onClick={copyOrder}> Copy </Button>
            <Button className={classes.orderActionButton} onClick={() => { window.print() }}> Print </Button>
          </div>
        </div>
        <OrderBasicDetails
          suite={suite}
          companyName={companyName}
          eventName={eventName}
          eventDate={eventDate}
          createdDate={createdDate}
          total={total}
          menuTypeName={menuTypeName}
          paymentMethodName={paymentMethodName}
          paymentStatusName={paymentStatusName}
          submittedBy={submittedBy}
        />
        <InSuiteActionOptions checkoutOptionsSummary={checkoutOptionsSummary} hideParOptions={!actionBarData.isEditableByCurrentSESUser} />
        <div className={classes.itemsAndPaymentWrapper}>
          <div className={classes.orderItemsContainerList}>
            <OrderItemsListHeader />
            <OrderItemsList orderItems={orderItems} />
            <BillSummaryLineItem label={subtotalLabel} value2={orderSummary.subTotal} paymentLineItem />
          </div>
          <div className={classes.paymentSummaryContainer}>
            <BillSummaryLineItem label={taxLabel} value1={'8.875%'} value2={orderSummary.taxAmount} />
            <BillSummaryLineItem label={gratuityLabel} value1={`${orderSummary.gratuityPercentage}%`} value2={orderSummary.gratuityAmount} />
            <BillSummaryLineItem label={tipFromTerminalLabel} value2={tipFromTerminal} />
            <BillSummaryLineItem label={totalLabel} value2={orderSummary.total} paymentLineItem />
            { checkoutOptionsSummary && checkoutOptionsSummary.paymentDetails && getPaymentDetails(checkoutOptionsSummary) }
          </div>
        </div>
      </div>
      { order && openCopyOrderDialog && <CopyOrder
        openDialog={openCopyOrderDialog}
        handleDialogClose={closeCopyOrderDialog}
        handleCopyOrder={handleCopyOrder}
        order={order}
      /> }
      <Confirmation
        isOpen={openCancelOrderConfirmationDialog}
        onClose={handleCancelConfirmationDialogClose}
        onSubmit={cancelOrder}
        message={CancelOrderConfirmationMessage}
      />
    </React.Fragment>
  )
}

OrderDetail.propTypes = {
  orderItems: PropTypes.object,
  order: PropTypes.object,
  fetchOrder: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  basicOrderDetails: PropTypes.object,
  orderSummary: PropTypes.object,
  actionBarData: PropTypes.object,
  checkoutOptionsSummary: PropTypes.object,
  fetchCopyOrderData: PropTypes.func,
  resetPaymentOptions: PropTypes.func,
  getEventDetailsWithOrderWindow: PropTypes.func,
  orderCancellationStatus: PropTypes.bool,
  orderCancellationMessage: PropTypes.string,
  cancelOrderById: PropTypes.func,
  resetOrderCancellationNotice: PropTypes.func
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.orderReducer.OrderByIdData,
    orderCancellationStatus: state.orderReducer.orderCancellationStatus,
    orderCancellationMessage: state.orderReducer.orderCancellationMessage
  }
}
const mapDispatchToProps = {
  fetchOrder: fetchOrderById,
  fetchCopyOrderData,
  resetPaymentOptions,
  getEventDetailsWithOrderWindow,
  cancelOrderById,
  resetOrderCancellationNotice
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(OrderDetail))
)
