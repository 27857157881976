import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import CreditCard from '../CreditCard/CreditCard'
import AddCreditCard from '../../Dialogs/AddCreditCard/AddCreditCard'
import { storeUserCreditCards, tokenizeCreditCard, initiateAddCreditCard, removeFreedomPayIframe, clearAddCreditCardError, hideUpdateCardNameForm } from '../../store/creditCard/actions'
import {
  reportAnalytics
} from '../../utils/analytics'
import {
  ANALYTICS_ACTION_FILTER_CLICK,
  ANALYTICS_ACTION_ADD_ADDITIONAL_CARDS,
  ANALYTICS_ACTION_TYPE_ADD_ADDITIONAL_CARDS,
  ANALYTICS_PAGE_TYPE_CHECKOUT,
  ANALYTICS_ACTION_LOCATION_BODY
} from '../../constants/analytics'
import { getUserPaymentDetails } from '../../store/CheckoutFlow/actions'

import styles from './CreditCardsList.css'
const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = {
  initiateAddCreditCard,
  removeFreedomPayIframe,
  tokenizeCreditCard,
  clearAddCreditCardError,
  hideUpdateCardNameForm,
  getUserPaymentDetails
}

const getUsedCardsListToRender = (allCards, usedCreditCards, handleInput, handleDelete) => {
  if (usedCreditCards.length <= 0) return
  return usedCreditCards.map(usedCard => {
    const currentCard = allCards.find(card => card.id === usedCard.id)
    return <CreditCard
      key={usedCard.id}
      userCreditCards={[currentCard]}
      handleInput={handleInput}
      handleDelete={handleDelete}
      currentAmount={usedCard.amount}
      currentIsPrimary={usedCard.isPrimary}
      currentSelectedCardId={usedCard.id}
      enableDelete
    />
  })
}

const getDefaultCreditCardToRender = (allCards, usedCreditCards, handleInput, handleDelete, usedCard) => {
  let availableCards = []
  if (usedCreditCards.length > 0) {
    const usedCreditCardIds = usedCreditCards.map(card => card.id)
    availableCards = allCards.filter(availableCard => usedCreditCardIds.indexOf(availableCard.id) === -1)
  } else {
    const noCardPlaceholder = {
      cardName: 'Please, add a card to your Account.',
      card_name: null,
      card_type: null,
      expiry_date: null,
      id: null,
      last_four_digits: null
    }
    availableCards = allCards.length === 0 ? [noCardPlaceholder] : allCards
  }

  return <CreditCard
    userCreditCards={availableCards}
    handleInput={handleInput}
    handleDelete={handleDelete}
    currentIsPrimary={usedCard.isPrimary}
    enableDelete={usedCreditCards.length > 0}
  />
}

const isAddMoreButtonVisible = (allCards, usedCreditCards, isSesUser) => {
  if (isSesUser) return false
  return usedCreditCards.length < allCards.length && usedCreditCards.length < 6
}

const CreditCardsList = ({
  availableCreditCards,
  storeCreditCardPaymentDetails,
  usedCreditCards,
  orderId,
  classes,
  isSesUser,
  addCardErr,
  freedompayIframe,
  showFormToNameCreditCard,
  clearAddCreditCardError,
  tokenizeCreditCard,
  initiateAddCreditCard,
  removeFreedomPayIframe,
  userId,
  hideUpdateCardNameForm,
  getUserPaymentDetails,
  suiteId
}) => {
  const [showDefaultCard, setShowDefaultCard] = useState(false)
  const [addedCreditCard] = useState()
  const [openAddCardDialog, setOpenAddCardDialog] = useState()
  const [iframeRequestInProgress, setIframeRequestInProgress] = useState(false)

  useEffect(() => {
    if (freedompayIframe) {
      setIframeRequestInProgress(false)
    }
  }, [freedompayIframe])
  const handleAddCardDialogClose = () => {
    setOpenAddCardDialog(false)
  }
  const closeFeedomPayModal = () => {
    clearAddCreditCardError()
    hideUpdateCardNameForm()
    setOpenAddCardDialog(false)
    setTimeout(() => {
      getUserPaymentDetails(userId, suiteId)
    }, 1000)
  }

  const handleAddCard = (cardName) => {
    storeUserCreditCards(addedCreditCard, cardName, userId)
  }

  const initiateAddCardRedirection = () => {
    initiateAddCreditCard()
    setOpenAddCardDialog(true)
  }

  const handleInput = (cardData) => {
    const currentCardIndex = usedCreditCards.findIndex(card => card.id === cardData.id)
    if (cardData.isPrimary) {
      usedCreditCards.map(card => {
        card.isPrimary = false
        return card
      })
    }

    if (usedCreditCards.length === 0) {
      cardData.isPrimary = true
    }

    if (currentCardIndex > -1) {
      usedCreditCards[currentCardIndex] = cardData
    } else {
      usedCreditCards.push(cardData)
    }
    setShowDefaultCard(false)
    storeCreditCardPaymentDetails(usedCreditCards.slice())
  }

  const handleDelete = (selectedCardId) => {
    if (selectedCardId) {
      const cardIndexToDelete = usedCreditCards.findIndex(card => card.id === selectedCardId)
      usedCreditCards.splice(cardIndexToDelete, 1)
      if (usedCreditCards.length > 0) {
        usedCreditCards[0].isPrimary = true
      }
      storeCreditCardPaymentDetails(usedCreditCards.slice())
    } else {
      setShowDefaultCard(false)
    }
  }

  const addAdditionalCard = () => {
    reportAnalytics({
      eventType: ANALYTICS_ACTION_FILTER_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_ADD_ADDITIONAL_CARDS,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionType: ANALYTICS_ACTION_TYPE_ADD_ADDITIONAL_CARDS,
        actionOrderId: orderId
      },
      pageType: ANALYTICS_PAGE_TYPE_CHECKOUT
    })
    setShowDefaultCard(true)
  }

  return (
    <React.Fragment>
      { getUsedCardsListToRender(availableCreditCards, usedCreditCards, handleInput, handleDelete, storeCreditCardPaymentDetails) }
      { usedCreditCards.length === 0 || showDefaultCard ? getDefaultCreditCardToRender(availableCreditCards, usedCreditCards, handleInput, handleDelete, {}) : ''}
      <Button className={classes.addMoreCardButton} onClick={initiateAddCardRedirection}>Add a new Card</Button>
      { isAddMoreButtonVisible(availableCreditCards, usedCreditCards, isSesUser) ? <Button onClick={addAdditionalCard} className={classes.addMoreCardButton}>Add Additional Cards</Button> : ''}
      <AddCreditCard
        cardObject={addedCreditCard}
        clearAddCreditCardError={clearAddCreditCardError}
        openAddCardDialog={openAddCardDialog}
        handleAddCard={handleAddCard}
        handleAddCardDialogClose={handleAddCardDialogClose}
        iframeRequestInProgress={iframeRequestInProgress}
        freedompayIframe={freedompayIframe}
        removeFreedomPayIframe={removeFreedomPayIframe}
        tokenizeCreditCard={tokenizeCreditCard}
        closeFeedomPayModal={closeFeedomPayModal}
        showFormToNameCreditCard={showFormToNameCreditCard}
        suiteHolderUserId={userId}
        addCardErr={addCardErr}
        closeCCModal={() => {
          closeFeedomPayModal()
        }}
        stopReload
      />
    </React.Fragment>
  )
}

CreditCardsList.propTypes = {
  availableCreditCards: PropTypes.array,
  storeCreditCardPaymentDetails: PropTypes.func,
  usedCreditCards: PropTypes.array,
  classes: PropTypes.object,
  orderId: PropTypes.number,
  isSesUser: PropTypes.bool,
  addCardErr: PropTypes.object,
  clearAddCreditCardError: PropTypes.func,
  freedompayIframe: PropTypes.string,
  showFormToNameCreditCard: PropTypes.bool,
  userId: PropTypes.string,
  suiteId: PropTypes.string,
  tokenizeCreditCard: PropTypes.func,
  initiateAddCreditCard: PropTypes.func.isRequired,
  removeFreedomPayIframe: PropTypes.func,
  hideUpdateCardNameForm: PropTypes.func,
  getUserPaymentDetails: PropTypes.func
}

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditCardsList))
