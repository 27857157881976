import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  TablePagination
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import EventDate from '../../elements/EventDate/EventDate'
import DataContainer from '../../elements/DataContainer/DataContainer'
import { history } from '../../store/store'
import { fetchClientEvents, filterClientEvents } from '../../store/ClientEvents/actions'
import { resetNewOrderData } from '../../store/companies/actions'
import ClientAddOrder from '../../Dialogs/ClientAddOrder/ClientAddOrder'
import ClientEventsFilterBar from '../../elements/ClientEventsFilterBar/ClientEventsFilterBar'
import FilterBar from '../../elements/FilterBar/FilterBar'
import TablePaginationActions from '../../elements/TablePaginationActions/TablePaginationActions'
import { reportGoogleAnalytics } from '../../utils/googleAnalytics'
import isEmpty from '../../store/validation/is-empty'
import {
  rowsPerPageOptions,
  defaultPage,
  defaultLimit,
  defaultTotalPages
} from '../../constants/pagination'
import {
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_ACTION_CLIENT_ADD_ORDER,
  ANALYTICS_ACTION_LOCATION_BODY,
  ANALYTICS_PAGE_TYPE_CLIENT_EVENTS,
  ANALYTICS_PAGE_TYPE_CLIENT_EVENT_MODAL,
  ANALYTICS_ACTION_TYPE_EVENT_DETAILS,
  ANALYTICS_ACTION_TYPE_EVENT_MODAL_ADD_ORDER,
  ANALYTICS_ACTION_LOCATION_MODAL
} from '../../constants/analytics'
import { reportAnalytics } from '../../utils/analytics'
import ClientEventDetails from './ClientEventDetails'
import styles from './ClientEvents.css'

const ClientEvents = ({
  classes,
  rows,
  totalEventsCount = 0,
  totalPages = defaultTotalPages,
  page = defaultPage,
  limit: rowsPerPage = defaultLimit,
  fetchItems,
  filterItems,
  filters,
  sortParams,
  eventCategories,
  currentUser,
  suites,
  resetNewOrderData,
  fetchClientEventsInProgress,
  updateActiveOrders
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState()

  useEffect(() => {
    if (!rows) {
      fetchItems({})
    }
  }, [fetchItems, rows, fetchClientEventsInProgress])

  useEffect(() => {
    if (updateActiveOrders) {
      fetchItems({})
    }
  }, [updateActiveOrders, fetchItems])

  const handleDialogClose = () => {
    setOpenDialog(false)
    setSelectedEvent({})
  }

  function getAnalyticsDetails (suiteId) {
    if (!isEmpty(suiteId)) {
      const suite = suites.filter((s) => s.id === suiteId)
      return suite[0]?.display_name
    }
    return ''
  }

  const getSuites = () => { return currentUser.single_event_suite_user ? suites.filter((s) => selectedEvent?.suiteIds?.includes(s.id)) : suites }

  const handleAddOrder = (menuTypeId, selectedSuiteId) => {
    const suiteName = getAnalyticsDetails(selectedSuiteId)
    const { id: eventId, webName: eventName } = selectedEvent
    const { id: userId, company_id: companyId } = currentUser
    reportGoogleAnalytics('client_order_creation', {
      company_selection: companyId,
      suite_selection: suiteName,
      event_selection: eventName
    })
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_CLIENT_ADD_ORDER,
        actionLocation: ANALYTICS_ACTION_LOCATION_MODAL,
        actionType: ANALYTICS_ACTION_TYPE_EVENT_MODAL_ADD_ORDER
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_EVENT_MODAL
    })
    history.push(`/menu-catalog?companyId=${companyId}&eventId=${eventId}&menuTypeId=${menuTypeId}&suiteId=${selectedSuiteId}&userId=${userId}`)
  }

  const handleShowEventDetails = (event) => {
    reportGoogleAnalytics('create_order')
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_CLIENT_ADD_ORDER,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionType: ANALYTICS_ACTION_TYPE_EVENT_DETAILS
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_EVENTS
    })
    resetNewOrderData()
    setSelectedEvent(event)
    setOpenDialog(true)
  }

  const handleChangePage = (event, page) => {
    filterItems(filters, page, rowsPerPage, sortParams)
  }

  const handleChangeRowsPerPage = event => {
    filterItems(filters, defaultPage, +event.target.value, sortParams)
  }

  const resetPaginationToDefault = (filters) => {
    filterItems(filters, defaultPage, rowsPerPage, sortParams)
  }

  const applyFilter = (filters, page, rowsPerPage, sortParams) => {
    filterItems(filters, defaultPage, rowsPerPage, sortParams)
  }

  return (
    <div>
      <DataContainer>
        <FilterBar fetchItems={applyFilter} page={page} limit={rowsPerPage} sortParams={sortParams} filters={filters} styles={styles}>
          <ClientEventsFilterBar
            eventCategories={eventCategories}
            resetPaginationToDefault={resetPaginationToDefault}
          />
        </FilterBar>
        <div className={classes.eventsListContainer}>
          { rows && rows.map(row => {
            return <div key={row.id} className={classes.eventContainer}>
              <EventDate
                eventMonth={row.startDateMonth}
                eventDate={row.startDate}
                eventDay={row.startDateDay}
              />
              <ClientEventDetails event={row} handleShowEventDetails={handleShowEventDetails} sesUser={currentUser.single_event_suite_user} />
            </div>
          })}
        </div>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={3}
          count={totalEventsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            native: true
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          classes={{
            caption: classes.tablePaginationCaption,
            selectIcon: classes.tablePaginationSelectIcon,
            select: classes.tablePaginationSelect,
            actions: classes.tablePaginationActions
          }}
        />
        <ClientAddOrder
          openDialog={openDialog}
          handleDialogClose={handleDialogClose}
          event={selectedEvent}
          handleAddOrder={handleAddOrder}
          suites={getSuites()}
        />
      </DataContainer>
    </div>
  )
}

ClientEvents.propTypes = {
  classes: PropTypes.object,
  rows: PropTypes.array,
  fetchItems: PropTypes.func,
  filterItems: PropTypes.func,
  eventCategories: PropTypes.array.isRequired,
  totalEventsCount: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  filters: PropTypes.object,
  sortParams: PropTypes.object,
  currentUser: PropTypes.object,
  suites: PropTypes.array,
  resetNewOrderData: PropTypes.func,
  fetchClientEventsInProgress: PropTypes.bool,
  updateActiveOrders: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    ...state.clientEventsData.clientEventsList,
    fetchClientEventsInProgress: state.clientEventsData.fetchClientEventsInProgress,
    eventCategories: state.staticData.event_categories,
    currentUser: state.authReducer.user,
    updateActiveOrders: state.checkoutData.updateActiveOrders,
    suites: state.staticData.suites
  }
}

const mapDispatchToProps = {
  fetchItems: fetchClientEvents,
  filterItems: filterClientEvents,
  resetNewOrderData
}

export default React.memo(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ClientEvents)
  )
)
