import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import idx from 'idx'
import { connect } from 'react-redux'
import { getCompanyDetailsById } from '../../store/companies/actions'
import DataContainer from '../../elements/DataContainer/DataContainer'
import SuiteHolderUsersList from '../SuiteHolderUsers/SuiteHolderUsersList'
import AssignEventsDialog from '../../Dialogs/AssignEvents/AssignEvents'
import QuickNotice from '../../elements/QuickNotice/QuickNotice'
import styles from './CompanyDetails.css'

const CompanyDetails = ({
  classes,
  companyDetails,
  getCompanyDetailsById,
  route,
  eventAssignmentMessage
}) => {
  const [isAssignEventsDialogOpen, setIsAssignEventsDialogOpen] = useState(false)
  const [suiteId, setSuiteId] = useState(null)
  const [companyAssignment, setCompanyAssignment] = useState(false)
  const companyId = idx(route, _ => _.match.params.suite_holder_id)
  const [updateMessage, setUpdateMessage] = useState('')

  useEffect(() => {
    getCompanyDetailsById(companyId)
  }, [route, getCompanyDetailsById, companyId])

  useEffect(() => {
    if (eventAssignmentMessage) {
      setUpdateMessage(eventAssignmentMessage)
      const timer1 = setTimeout(() => setUpdateMessage(''), 2000)
      return () => {
        clearTimeout(timer1)
      }
    }
  }, [eventAssignmentMessage])

  const closeDialog = () => {
    setIsAssignEventsDialogOpen(false)
  }

  const openDialog = (suiteId, companiesSuiteId) => {
    setSuiteId(suiteId)
    setCompanyAssignment(true)
    setIsAssignEventsDialogOpen(true)
  }

  return (
    <div>
      { updateMessage && <QuickNotice message={updateMessage} /> }
      {
        <DataContainer>
          {
            companyDetails &&
            <div className={classes.detailsHeader}>
              <div className={classes.companyName}>{companyDetails.name}</div>
              <div className={classes.fieldContainer}>
                <div className={classes.fieldTitle}>ORACLE ID</div>
                <div className={classes.fieldValue}>{companyDetails.oracleId}</div>
              </div>
              <div className={classes.fieldContainer}>
                {
                  companyDetails.assignedSuites.map(suite =>
                    <Fragment key={suite.suiteName}>
                      <div className={classes.fieldTitle}>SUITES</div>
                      <div className={classes.fieldValue}>{suite.suiteName}</div>
                    </Fragment>
                  )
                }
              </div>
              <div className={classes.fieldContainer}>
                {
                  companyDetails.assignedSuites.map(suite =>
                    <Fragment key={suite.suiteName}>
                      <div className={classes.fieldTitle}>SHARE TYPE</div>
                      <div className={classes.fieldValue}>
                        {
                          `${suite.shareTypeName} ${suite.shareTypeName !== 'N/A' ? ', Active Since: ' + suite.activeSince : ''}`
                        }
                      </div>
                    </Fragment>
                  )
                }
              </div>
              <div className={`${classes.fieldContainer} ${classes.assignedValueBox}`}>
                {
                  companyDetails.assignedSuites.map(suite =>
                    <div className={classes.actionsContainer} key={suite.suiteName}>
                      <Button className={classes.assignEventButton} onClick={() => openDialog(suite.suiteId, suite.companiesSuiteId)}>Assign Events</Button>
                    </div>
                  )
                }
              </div>
            </div>
          }
          <div className={classes.separator} />

          <div className={classes.usersContainer}>
            <SuiteHolderUsersList companyId={companyId} />
          </div>
        </DataContainer>
      }
      {
        isAssignEventsDialogOpen && <AssignEventsDialog
          isOpen={isAssignEventsDialogOpen}
          onClose={closeDialog}
          suites={companyDetails.assignedSuites}
          companyName={companyDetails.name}
          id={suiteId}
          companyAssignment={companyAssignment}
          companyId={companyId}
        />
      }
    </div>
  )
}

CompanyDetails.propTypes = {
  classes: PropTypes.object,
  companyDetails: PropTypes.object,
  getCompanyDetailsById: PropTypes.func,
  route: PropTypes.object,
  eventAssignmentMessage: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    companyDetails: state.companiesData.companyDetails,
    eventAssignmentMessage: state.companiesData.eventAssignmentMessage
  }
}

const mapDispatchToProps = {
  getCompanyDetailsById
}

export default React.memo(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CompanyDetails)
  )
)
