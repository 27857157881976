const styles = {
  detailsHeader: {
    display: 'flex',
    padding: '20px 10px 20px'
  },
  fieldTitle: {
    color: 'black',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '1.1rem',
    fontWeight: '400',
    lineHeight: '1',
    marginBottom: '6px'
  },
  fieldValue: {
    height: '50px',
    minWidth: '100px',
    maxWidth: '200px',
    color: 'black',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '26px'
  },
  assignEventButton: {
    color: 'white',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    height: '50px',
    width: '170px',
    backgroundColor: '#0078D4',
    borderRadius: '0px',
    float: 'right',
    '&:hover': {
      backgroundColor: '#0078D4'
    }
  },
  actionsContainer: {
    marginLeft: '4px',
    '& button': {
      marginBottom: '18px',
      marginTop: '1px'
    }
  },
  separator: {
    boxSizing: 'border-box',
    height: '2px',
    width: '1210px',
    border: '1px solid #4D4E58'
  },
  assignedValueBox: {
    display: 'inline-grid'
  },
  suitesListClass: {
    display: 'flex'
  }
}

export default styles
