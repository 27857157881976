import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { validatePassword, getPasswordRequirements, getParameterByKey } from '../../utils/validations'
import { updateNewPasswordByToken, resetUpdatePasswordMessage } from '../../store/authentication/actions'
import styles from './ResetPassword.css'

const ResetPassword = ({ classes, updateNewPasswordByToken, newPasswordUpdateMessage, resetUpdatePasswordMessage }) => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmationPassword, setConfirmationPassword] = useState('')
  const [updatePwdMessage, setUpdatePasswordMessage] = useState('')
  const resetToken = getParameterByKey('token')
  const singleEventNewUser = getParameterByKey('ses_user')

  useEffect(() => {
    if (newPasswordUpdateMessage) {
      setUpdatePasswordMessage(newPasswordUpdateMessage)
      const timer1 = setTimeout(() => {
        resetUpdatePasswordMessage()
        setConfirmationPassword('')
        setNewPassword('')
        window.location.reload()
      }, 9000)
      return () => {
        clearTimeout(timer1)
      }
    }
  }, [newPasswordUpdateMessage, resetUpdatePasswordMessage])

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value)
  }

  const handleConfirmPassword = (e) => {
    setConfirmationPassword(e.target.value)
  }

  const verifyNewPassword = (e) => {
    e.preventDefault()
    const user = {
      password: newPassword,
      password_confirmation: confirmationPassword,
      reset_password_token: resetToken
    }
    updateNewPasswordByToken({
      user
    })
  }
  return (
    <Paper className={classes.root}>
      <div className={classes.headerGrid}>
        <div className={classes.logoContainer}>
          <img src="/msgLogo.png" alt="" className={classes.suitesImage} />
        </div>
        <div className={classes.suitesBackground} />
      </div>
      <div className={classes.contentContainer}>
        <h2 className={classes.signInHeaderText}>
          { singleEventNewUser === 'true' ? 'Create Password' : 'Reset Password'}
        </h2>
        <div className={classes.formContainer}>
          <form onSubmit={verifyNewPassword}>
            {
              !updatePwdMessage && <Fragment>
                <TextField
                  className={classes.textField}
                  type="password"
                  margin="normal"
                  variant="outlined"
                  placeholder="Password"
                  onChange={handleNewPassword}
                  autoComplete="off"
                  value={newPassword}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  id="outlined-password-input"
                  label=""
                  name="password"
                  className={classes.textField}
                  type="password"
                  margin="normal"
                  autoComplete="off"
                  variant="outlined"
                  placeholder="Confirm Password"
                  onChange={handleConfirmPassword}
                  value={confirmationPassword}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <div className={classes.signInButtonContainer}>
                  <Button variant="contained" type="submit" color="primary" disabled={!validatePassword(newPassword, confirmationPassword, resetToken)}>
                    {singleEventNewUser === 'true' ? 'CONFIRM' : 'RESET'}
                  </Button>
                </div>
              </Fragment>
            }
            {
              updatePwdMessage && <Typography variant="h6" className={classes.updatePwdMessage}>{updatePwdMessage}</Typography>
            }
            {
              (singleEventNewUser === 'true' && updatePwdMessage) ? <Link className={classes.forgotPwdLink} to={'/login'}>
                <span>Go to Login Page</span>
              </Link> : ''
            }
            { singleEventNewUser !== 'true' && <Link className={classes.forgotPwdLink} to={'/login'}>
              <span>Back to Login</span>
            </Link>}
          </form>
          {
            !updatePwdMessage && <div className={classes.resetPassRequirements}>
              <Typography variant="h6" className={classes.suitesLabel}>Password requirements:</Typography>
              <ul>
                {
                  getPasswordRequirements().map((req, index) => <li key={index}>{req}</li>)
                }
              </ul>
            </div>
          }
        </div>
      </div>
    </Paper>
  )
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  updateNewPasswordByToken: PropTypes.func.isRequired,
  newPasswordUpdateMessage: PropTypes.string,
  resetUpdatePasswordMessage: PropTypes.func
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  resetPwd: state.authReducer.resetPwd,
  newPasswordUpdateMessage: state.authReducer.newPasswordUpdateMessage,
  resetUpdatePasswordMessage: state.authReducer
})

export default withStyles(styles)(connect(mapStateToProps, { updateNewPasswordByToken, resetUpdatePasswordMessage })(ResetPassword))
