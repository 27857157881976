import {
  SUITE_HOLDER_USER_UPDATED,
  UPDATE_SUITE_HOLDER_USER_FORM_DATA,
  FETCH_SUITE_HOLDER_USERS,
  SET_SUITE_HOLDER_USER_TO_EDIT,
  SET_SUITE_HOLDER_USER_TO_ADD,
  CLOSE_SUITE_HOLDER_USER_DIALOG,
  SET_SUITE_HOLDER_DATA_FROM_LOCALSTORAGE,
  SET_NEW_CREDIT_CARD_FOR_SUITE_HOLDER_USER,
  REMOVE_NEW_CREDIT_CARD_FROM_SUITE_HOLDER_USER,
  UPDATE_CREDIT_CARD_DATA_TO_STORE,
  DELETE_USER_CREDIT_CARD_ERR,
  STORE_RECOMMENDATIONS,
  STORE_FAVORITE_RECOMMENDATIONS,
  RESET_IFRAME_CODE
} from '../actionTypes'
import store, { history } from '../store'
import { logoutUser } from '../authentication/actions'
import { suiteHolderUsersEndpoints, companyEndpoints } from '../../constants/apiConfig'
import { cleanUpFilterObject } from '../helpers/cleanupFilters'
import { sortParamsMapping, SUITE_HOLDER_USER_NEW_DATA } from '../../constants/suiteHolderUsers'
import { getItemsList } from '../helpers/apiClient'
import { currentUserIsAdmin } from '../helpers/userRoleManager'

const prepareSuiteHolderUserListQuery = (filters, page, limit, sort) => {
  const { query } = cleanUpFilterObject(filters)
  const { sortBy = '', order = '' } = sort
  return {
    filters: {
      query
    },
    pagination: {
      page: page + 1,
      limit
    },
    sort: {
      order_by: sortParamsMapping[sortBy],
      order
    }
  }
}

const serializeSHUserData = (data) => {
  const {
    company_id: companyId,
    contact_number: contactNumber,
    email,
    first_name: firstName,
    id,
    last_name: lastName,
    role_id: roleId,
    web_status: webStatus,
    single_event_suite_user: sesUser
  } = data
  return {
    firstName,
    lastName,
    contactNumber,
    email,
    id,
    companyId,
    roleId,
    webStatus,
    sesUser
  }
}

const fetchCompanySuitesById = async (companyId, dispatch) => {
  const { method, url } = companyEndpoints.getSuites
  const urlWithId = url.replace(':companyId', companyId)
  const suitesResponse = await getItemsList(method, urlWithId)
  try {
    const { data = {} } = suitesResponse
    const { suites } = data
    return suites
  } catch (error) {
    console.log(error)
    dispatch(logoutUser(history))
  }
}

export const fetchSuiteHolderUsers = (companyId, filters = {}, page = 0, limit = 10, sortParams = {}) => dispatch => {
  const {
    user: currentUser = {}
  } = store.getState().authReducer
  let endpointData
  if (currentUserIsAdmin(currentUser)) {
    endpointData = companyEndpoints.getUsersByCompany(companyId)
  } else {
    endpointData = companyEndpoints.getUsersByCompany(currentUser.company_id)
  }
  const { method, url } = endpointData
  const data = prepareSuiteHolderUserListQuery(filters, page, limit, sortParams)
  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      const { suite_holder_users: items, meta } = data
      dispatch({
        type: FETCH_SUITE_HOLDER_USERS,
        payload: { items, meta, page, limit, filters, sortParams }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

const getAllCompanySuites = (userSuiteList) => {
  const companySuites = {}
  const companiesSuiteIds = userSuiteList.filter(suite => suite.is_assigned)
  companiesSuiteIds.forEach((suite) => {
    companySuites[suite.companies_suite_id] = suite
  })
  return companySuites
}

const updateSuites = (allSuites, companySuites) => {
  return allSuites.map((item) => {
    const userSuite = companySuites[item.companies_suite_id]
    for (const key in userSuite) {
      item[key] = userSuite[key]
    }
    return item
  })
}

export const fetchSuiteHolderUserById = (companies, roleList, userId) => async dispatch => {
  const endpointData = suiteHolderUsersEndpoints.fetchById(userId)
  const paymentDetails = suiteHolderUsersEndpoints.paymentDetails
  const { method, url } = endpointData
  const urlWithId = `${url.replace(':suiteHolderUserId', userId)}`
  const paymentFetchUrl = `${paymentDetails.url.replace(':suiteHolderUserId', userId)}`
  try {
    const sHUser = await getItemsList(method, urlWithId)
    const sHUserPaymentDetails = await getItemsList(method, paymentFetchUrl)
    const { data: sHUserData = {} } = sHUser
    const { suite_user: { suite_holder_user: suiteHolderUser, suites } } = sHUserData
    const { company_id: companyId } = suiteHolderUser
    const suitesList = await fetchCompanySuitesById(companyId, dispatch)
    const serializedData = serializeSHUserData(suiteHolderUser)
    const userData = {
      suiteHolderUser: serializedData,
      suites
    }
    const companySuites = getAllCompanySuites(suites)
    const allSuites = [...suitesList]
    const updatedSuites = updateSuites(allSuites, companySuites)
    const { data: paymentDetailsData = {} } = sHUserPaymentDetails
    const { payment_details: paymentDetails = {} } = paymentDetailsData

    dispatch({
      type: RESET_IFRAME_CODE,
      payload: {}
    })

    dispatch({
      type: SET_SUITE_HOLDER_USER_TO_EDIT,
      payload: {
        user: {
          ...userData
        },
        suitesList: updatedSuites,
        mode: 'Edit',
        dialogOpenStatus: true,
        paymentDetails,
        companies,
        roleList
      }
    })
  } catch (error) {
    console.log(error)
    dispatch(logoutUser(history))
  }
}

export const addNewUser = (companies, roleList) => async dispatch => {
  try {
    const [company] = companies
    const suitesList = await fetchCompanySuitesById(company.id, dispatch)
    const user = JSON.parse(JSON.stringify(SUITE_HOLDER_USER_NEW_DATA))
    const validateUser = () => {
      if (company.name === 'SES CUSTOMER') {
        return user
      } else {
        user.suiteHolderUser.sesUser = false
        return user
      }
    }

    dispatch({
      type: SET_SUITE_HOLDER_USER_TO_ADD,
      payload: {
        user: validateUser(),
        suitesList,
        mode: 'Add',
        paymentDetails: { credit_card_details: [], companies_suites_details: [] },
        dialogOpenStatus: true,
        companies,
        roleList
      }
    })
  } catch (error) {
    console.log(error)
    dispatch(logoutUser(history))
  }
}

export const closeDialog = (companies, roleList) => async dispatch => {
  try {
    const [company] = companies
    const suitesList = await fetchCompanySuitesById(company.id, dispatch)
    dispatch({
      type: CLOSE_SUITE_HOLDER_USER_DIALOG,
      payload: {
        user: null,
        suitesList,
        mode: null,
        paymentDetails: [],
        dialogOpenStatus: false,
        companies,
        roleList
      }
    })
  } catch (error) {
    console.log(error)
    dispatch(logoutUser(history))
  }
}

export const activateSuiteHolderUser = (suiteHolderUserId) => dispatch => {
  try {
    const { method, url } = suiteHolderUsersEndpoints.activateUser(suiteHolderUserId)
    getItemsList(method, url, {})
      .then(res => {
        const {
          suiteHolderUserToProcess
        } = store.getState().suiteHolderUsersData
        const { suiteHolderUser } = suiteHolderUserToProcess
        const updatedSuitesHolderUser = {
          ...suiteHolderUser,
          webStatus: true
        }
        const updatedSuiteHolderUserDataToProcess = {
          ...suiteHolderUserToProcess,
          suiteHolderUser: updatedSuitesHolderUser
        }
        dispatch({
          type: UPDATE_SUITE_HOLDER_USER_FORM_DATA,
          payload: {
            suiteHolderUserToProcess: updatedSuiteHolderUserDataToProcess
          }
        })
      })
  } catch (error) {
    console.log(error)
    dispatch(logoutUser(history))
  }
}

export const deActivateSuiteHolderUser = (suiteHolderUserId) => dispatch => {
  try {
    const { method, url } = suiteHolderUsersEndpoints.deactivateUser(suiteHolderUserId)
    getItemsList(method, url, {})
      .then(res => {
        const {
          suiteHolderUserToProcess
        } = store.getState().suiteHolderUsersData
        const { suiteHolderUser } = suiteHolderUserToProcess
        const updatedSuitesHolderUser = {
          ...suiteHolderUser,
          webStatus: false
        }
        const updatedSuiteHolderUserDataToProcess = {
          ...suiteHolderUserToProcess,
          suiteHolderUser: updatedSuitesHolderUser
        }
        dispatch({
          type: UPDATE_SUITE_HOLDER_USER_FORM_DATA,
          payload: {
            suiteHolderUserToProcess: updatedSuiteHolderUserDataToProcess
          }
        })
      })
  } catch (error) {
    console.log(error)
    dispatch(logoutUser(history))
  }
}

const getCreditCardDetails = (creditCards) => {
  const creditCardAttributes = creditCards.map(({ id, card_name: cardName, card_type: cardType, transarmor_token: transarmorToken, expiry_date: expiryDate }) => {
    if (id) {
      return {
        id,
        card_name: cardName
      }
    } else {
      return {
        card_name: cardName,
        card_type: cardType,
        transarmor_token: transarmorToken,
        expiry_date: expiryDate
      }
    }
  })
  return creditCardAttributes
}

export const addSuiteHolderUser = (suiteHolderUser, companies, suites, paymentDetails) => dispatch => {
  try {
    const { method, url } = suiteHolderUsersEndpoints.addUser
    const [company] = companies
    const { credit_card_details: creditCardDetails = [] } = paymentDetails
    const creditCardAttributes = getCreditCardDetails(creditCardDetails)
    const companySuites = suites.filter(suite => suite.is_assigned)
    let companySuitesUsersAttributes = []
    if (companySuites.length > 0) {
      companySuitesUsersAttributes = companySuites.map(suite => {
        return {
          companies_suite_id: suite.companies_suite_id,
          use_credit: false,
          can_use_invoice: suite.can_use_invoice || false
        }
      })
    }
    const suiteUser = {
      first_name: suiteHolderUser.firstName,
      last_name: suiteHolderUser.lastName,
      email: suiteHolderUser.email,
      contact_number: suiteHolderUser.contactNumber,
      role_id: suiteHolderUser.roleId,
      company_id: company.id,
      company_suites_users_attributes: companySuitesUsersAttributes,
      credit_cards_attributes: creditCardAttributes,
      web_status: suiteHolderUser.webStatus,
      single_event_suite_user: suiteHolderUser.sesUser
    }
    getItemsList(method, url, { suite_user: suiteUser })
      .then(res => {
        const {
          data: { message }
        } = res
        dispatch({
          type: SUITE_HOLDER_USER_UPDATED,
          payload: {
            user: null,
            suitesList: suites,
            mode: null,
            dialogOpenStatus: false,
            companies,
            message
          }
        })
        dispatch(fetchSuiteHolderUsers(company.id))
      })
      .catch(error => {
        console.log(error)
        dispatch(logoutUser(history))
      })
  } catch (error) {
    console.log(error)
    dispatch(logoutUser(history))
  }
}

const getProcessedSuitesData = (companySuites) => {
  let companySuitesUsersAttributes = []
  if (companySuites.length > 0) {
    companySuitesUsersAttributes = companySuites.map(suite => {
      const { id } = suite
      return {
        companies_suite_id: suite.companiesSuiteId || suite.companies_suite_id,
        id: id || '',
        ...(suite.is_assigned && { use_credit: false, can_use_invoice: suite.can_use_invoice || false }),
        ...(!suite.is_assigned && { _destroy: 1 })
      }
    })
  }
  return companySuitesUsersAttributes
}

const getCompanySuites = (suites) => {
  const companySuites = suites.map(suite => {
    if (!suite.is_assigned) {
      suite._destroy = 1
    }
    return suite
  })
  return getProcessedSuitesData(companySuites)
}

export const updateSuiteHolderUser = (suiteHolderUser, suites, companies, paymentDetails) => dispatch => {
  try {
    const { method, url } = suiteHolderUsersEndpoints.updateUser(suiteHolderUser.id)
    const companySuitesUsersAttributes = getCompanySuites(suites)

    const suiteUser = {
      first_name: suiteHolderUser.firstName,
      last_name: suiteHolderUser.lastName,
      email: suiteHolderUser.email,
      contact_number: suiteHolderUser.contactNumber,
      role_id: suiteHolderUser.roleId,
      company_id: suiteHolderUser.companyId,
      company_suites_users_attributes: companySuitesUsersAttributes,
      web_status: suiteHolderUser.webStatus,
      single_event_suite_user: suiteHolderUser.sesUser
    }
    getItemsList(method, url, { suite_user: suiteUser })
      .then(res => {
        const {
          data: { message }
        } = res
        dispatch({
          type: SUITE_HOLDER_USER_UPDATED,
          payload: {
            user: null,
            suitesList: suites,
            mode: null,
            dialogOpenStatus: false,
            companies,
            message
          }
        })
      })
  } catch (error) {
    console.log(error)
    dispatch(logoutUser(history))
  }
}

export const setSuiteHolderUserDataFromLocalStorage = (suiteHolderUser) => dispatch => {
  dispatch({
    type: SET_SUITE_HOLDER_DATA_FROM_LOCALSTORAGE,
    payload: suiteHolderUser
  })
}

export const setAddedCreditCard = (creditCardData) => dispatch => {
  dispatch({
    type: SET_NEW_CREDIT_CARD_FOR_SUITE_HOLDER_USER,
    payload: creditCardData
  })
}

export const removeAddedCardFromStore = (creditCardData) => dispatch => {
  dispatch({
    type: REMOVE_NEW_CREDIT_CARD_FROM_SUITE_HOLDER_USER,
    payload: {
      creditCardData: null
    }
  })
}

export const addNewCreditCardToPaymentDetails = (creditCardData, cardName) => dispatch => {
  try {
    const {
      suiteHolderUserToProcess
    } = store.getState().suiteHolderUsersData

    const { paymentDetails } = suiteHolderUserToProcess
    const { credit_card_details: creditCardDetails } = paymentDetails

    const updatedCreditCardDetails = [...creditCardDetails]
    updatedCreditCardDetails.push(
      creditCardData
    )
    const updatedPaymentDetails = {
      ...paymentDetails,
      credit_card_details: updatedCreditCardDetails
    }
    const updatedSuiteHolderUserDataToProcess = {
      ...suiteHolderUserToProcess,
      paymentDetails: updatedPaymentDetails
    }
    dispatch({
      type: UPDATE_CREDIT_CARD_DATA_TO_STORE,
      payload: {
        suiteHolderUserToProcess: updatedSuiteHolderUserDataToProcess
      }
    })
  } catch (error) {
    console.log(error)
    dispatch(logoutUser(history))
  }
}

export const deletePaymentCardFromStore = (cardIndex) => dispatch => {
  try {
    const {
      suiteHolderUserToProcess
    } = store.getState().suiteHolderUsersData

    const { paymentDetails } = suiteHolderUserToProcess
    const { credit_card_details: updatedCreditCardDetails = [] } = paymentDetails
    const deletedCard = updatedCreditCardDetails[cardIndex]
    if (deletedCard) {
      updatedCreditCardDetails.splice(cardIndex, 1)
    }

    const updatedPaymentDetails = {
      ...paymentDetails,
      credit_card_details: updatedCreditCardDetails
    }
    const updatedSuiteHolderUserDataToProcess = {
      ...suiteHolderUserToProcess,
      paymentDetails: updatedPaymentDetails
    }
    dispatch({
      type: UPDATE_CREDIT_CARD_DATA_TO_STORE,
      payload: {
        suiteHolderUserToProcess: updatedSuiteHolderUserDataToProcess
      }
    })
  } catch (error) {
    console.log(error)
    dispatch(logoutUser(history))
  }
}

// delete a saved credit card
export const deleteCreditCardFromUser = (card) => dispatch => {
  const { id } = card
  const { method, url } = suiteHolderUsersEndpoints.deleteClientCreditCard(id)
  getItemsList(method, url)
    .then(res => {
      const { data } = res
      if (data.success) {
        const {
          suiteHolderUserToProcess
        } = store.getState().suiteHolderUsersData
        const { paymentDetails } = suiteHolderUserToProcess
        const { credit_card_details: updatedCreditCardDetails = [] } = paymentDetails
        const cardIndex = updatedCreditCardDetails.indexOf(card)
        const deletedCard = updatedCreditCardDetails[cardIndex]
        if (deletedCard) {
          updatedCreditCardDetails.splice(cardIndex, 1)
        }

        const updatedPaymentDetails = {
          ...paymentDetails,
          credit_card_details: updatedCreditCardDetails
        }
        const updatedSuiteHolderUserDataToProcess = {
          ...suiteHolderUserToProcess,
          paymentDetails: updatedPaymentDetails
        }
        dispatch({
          type: UPDATE_CREDIT_CARD_DATA_TO_STORE,
          payload: {
            suiteHolderUserToProcess: updatedSuiteHolderUserDataToProcess
          }
        })
      } else {
        dispatch({
          type: DELETE_USER_CREDIT_CARD_ERR,
          payload: {
            cardDeletionErr: data
          }
        })
      }
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const getRecommendations = (pageType) => dispatch => {
  const {
    orderItems,
    orderWindow,
    eventId,
    menuTypeId,
    companyId,
    suiteId,
    userId,
    billDetails: {
      billSummary = 0
    } = {}
  } = store.getState().checkoutData
  if (menuTypeId !== 1) return
  const { total } = billSummary
  const { method, url } = suiteHolderUsersEndpoints.recommendations(userId)
  const requestData = {
    order_items: orderItems,
    order_window: orderWindow,
    event_id: eventId,
    menu_type_id: menuTypeId,
    company_id: companyId,
    suite_id: suiteId,
    user_id: userId,
    order_total: total,
    page_type: pageType
  }
  getItemsList(method, url, requestData)
    .then(res => {
      const { data } = res
      const { recommendations } = data
      if (pageType === 'menu_catalog') {
        dispatch({
          type: STORE_FAVORITE_RECOMMENDATIONS,
          payload: { recommendations }
        })
      } else {
        dispatch({
          type: STORE_RECOMMENDATIONS,
          payload: { recommendations }
        })
      }
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}
