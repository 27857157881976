module.exports.suiteHolderUserProperties = [
  {
    key: 'name',
    label: 'NAME',
    sortable: true
  },
  {
    key: 'roleName',
    label: 'ROLE',
    sortable: true
  },
  {
    key: 'email',
    label: 'EMAIL',
    sortable: false
  },
  {
    key: 'isOracleAdmin',
    label: 'ORACLE ADMIN',
    sortable: false
  },
  {
    key: 'suiteDetails',
    label: 'SUITE',
    sortable: false
  },
  {
    key: 'phoneNumber',
    label: 'PHONE',
    sortable: false
  },
  {
    key: 'webStatusText',
    label: 'STATUS',
    sortable: true
  },
  {
    key: 'sesUser',
    label: 'SES User',
    sortable: true
  }
]
module.exports.rowsPerPageOptions = [10, 25, 50]

module.exports.sortParamsMapping = {
  name: 'users.name',
  roleName: 'roles.name',
  companyName: 'companies.name',
  sesUser: 'users.single_event_suite_user'
}

module.exports.rowsFor = 'suiteHolderUsers'
module.exports.resourceName = 'suite_holder_users'

module.exports.styles = {
  searchFieldWidth: '800px',
  submitButtonWidth: '250px'
}

module.exports.ctaLabel = 'Add Suite Holder User'
module.exports.SUITE_HOLDER_USER_NEW_DATA = {
  suiteHolderUser: {
    contactNumber: '',
    email: '',
    firstName: '',
    lastName: '',
    sesUser: true
  }
}
