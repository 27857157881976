import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import idx from 'idx'
import axios from 'axios'
import OrderBasicDetails from '../../elements/OrderBasicDetails/OrderBasicDetails'
import OrderItemsListHeader from '../../elements/OrderItemsListHeader/OrderItemsListHeader'
import OrderItemsList from '../../elements/OrderItemsList/OrderItemsList'
import InSuiteActionOptions from '../../elements/InSuiteActionOptions/InSuiteActionOptions'
import BillSummaryLineItem from '../../elements/BillSummaryLineItem/BillSummaryLineItem'
import { storeOrderItems } from '../../store/CheckoutFlow/actions'
import { history } from '../../store/store'
import CustomButton from '../../elements/CustomButton/CustomButton'
import { removeItemFromCart, updateQuantity } from '../Checkout/CheckoutHelpers'
import { formatFloatNumber } from '../../utils/formatters'
import {
  paymentSummaryLabels
} from '../../constants/OrderDetails'
import {
  GAMEDAY_PRICE_WINDOW,
  ADVANCE_PRICE_WINDOW,
  ADMIN_ROLES
} from '../../constants/menuItems'
import SearchMenuItems from '../../elements/SearchMenuItems/SearchMenuItems'
import { fetchOrderById } from '../../store/orders/actions'
import { menuItemEndpoints } from '../../constants/apiConfig'
import MenuItemDetails from '../../Dialogs/MenuItemDetails/MenuItemDetails'
import {
  reportAnalytics,
  reportClientEventsPageLoad
} from '../../utils/analytics'
import {
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_ACTION_BROWSE_CATALOG,
  ANALYTICS_ACTION_LOCATION_BODY,
  ANALYTICS_PAGE_TYPE_CLIENT_ORDERS_EDIT,
  ANALYTICS_ACTION_PROCEED_TO_CHECKOUT,
  ANALYTICS_ACTION_TYPE_EDIT_TO_CHECKOUT,
  ANALYTICS_ACTION_TYPE_CANCEL_EDIT,
  ANALYTICS_ACTION_CANCEL_EDIT,
  ANALYTICS_ACTION_TYPE_BROWSE_CATALOG,
  ANALYTICS_ACTION_CART_ADD,
  ANALYTICS_ACTION_CART_QUICK_ADD,
  ANALYTICS_ACTION_LOCATION_MODAL,
  ANALYTICS_ACTION_TYPE_CART_MODAL_ADD,
  ANALYTICS_ACTION_TYPE_CART_QUICK_ADD,
  ANALYTICS_EVENT_CART_MODAL_ADD
} from '../../constants/analytics'
import { ADD_PRODUCT_DATA, reportGoogleAnalytics } from '../../utils/googleAnalytics'
import styles from './EditOrder.css'

const {
  subtotalLabel,
  taxLabel,
  gratuityLabel,
  tipFromTerminalLabel,
  totalLabel
} = paymentSummaryLabels

const getCurrentMode = (path) => {
  return path.split('/').pop() === 'edit' ? 'Edit' : 'View'
}

const EditOrderDetail = ({
  orderByIdData: {
    actionBarData = {},
    basicOrderDetails = {},
    orderSummary = {},
    checkoutOptionsSummary = {}
  } = {},
  classes,
  fetchOrder,
  updateOrderItems,
  route,
  order,
  orderItems = [],
  orderWindow,
  billDetails = {},
  companyId,
  suiteId,
  menuTypeId,
  userId,
  eventId,
  currentUser,
  eventDetails = {}
}) => {
  const orderId = idx(route, _ => _.match.params.id)
  const currentPath = idx(route, _ => _.match.path)
  const mode = getCurrentMode(currentPath)

  const isCurrentUserAdmin = currentUser && ADMIN_ROLES.includes(currentUser.role)

  const [openMenuDetailsDialog, setOpenMenuDetailsDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const {
    billSummary = {}
  } = billDetails

  const handleQuantityChange = (itemId, quantity) => {
    updateQuantity(itemId, orderItems, quantity, orderWindow, updateOrderItems, orderId)
  }

  const handleRemoveItemFromCart = (itemId, itemOrderWindow) => {
    removeItemFromCart(itemId, orderItems, itemOrderWindow, updateOrderItems, orderId)
  }

  const onDialogClose = () => {
    setOpenMenuDetailsDialog(false)
    setSelectedItem({})
  }

  useEffect(props => {
    if (!order) {
      fetchOrder(orderId)
    }
  }, [orderId, fetchOrder, order])

  const {
    suite,
    companyName,
    eventName,
    eventDate,
    createdDate,
    total,
    menuTypeName,
    paymentMethodName,
    paymentStatusName,
    submittedBy
  } = basicOrderDetails
  const location = useLocation()
  useEffect(() => {
    if (currentUser && !isCurrentUserAdmin && eventDetails.eventDays) {
      const {
        name,
        eatecId,
        eventDays
      } = eventDetails
      reportClientEventsPageLoad(
        ANALYTICS_PAGE_TYPE_CLIENT_ORDERS_EDIT,
        location.pathname,
        {
          event_name: name,
          event_id: eatecId,
          event_days: eventDays
        }
      )
    }
  }, [location])

  const proceedToCheckout = () => {
    reportGoogleAnalytics('checkout_page_view', { event_name: eventName, event_date: eventDate, company_name: companyName })
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_PROCEED_TO_CHECKOUT,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionType: ANALYTICS_ACTION_TYPE_EDIT_TO_CHECKOUT,
        actionOrderId: orderId,
        actionOrderWindow: orderWindow
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ORDERS_EDIT
    })
    history.push('/checkout')
  }

  const cancelOrderEdit = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_CANCEL_EDIT,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionType: ANALYTICS_ACTION_TYPE_CANCEL_EDIT,
        actionOrderId: orderId,
        actionOrderWindow: orderWindow
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ORDERS_EDIT
    })
    history.goBack()
  }

  const addMenuItems = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_BROWSE_CATALOG,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionType: ANALYTICS_ACTION_TYPE_BROWSE_CATALOG,
        actionOrderId: orderId,
        actionOrderWindow: orderWindow
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ORDERS_EDIT
    })
    history.push(`/menu-catalog?companyId=${companyId}&eventId=${eventId}&suiteId=${suiteId}&userId=${userId}&menuTypeId=${menuTypeId}`)
  }

  const {
    tipFromTerminal = '0.00'
  } = orderSummary

  const activeOrderItems = orderItems.filter(item => item._destroy !== 1)

  const fetchMenuItems = (searchQuery) => {
    const data = {
      query: searchQuery,
      suite_id: suiteId,
      menu_type_id: menuTypeId,
      event_id: eventId
    }
    const { method, url } = menuItemEndpoints.search
    return axios({ method, url, data })
  }

  const handleMenuSelection = (menuItem) => {
    const {
      web_name: name,
      web_description: description,
      image,
      advance_price: advancePrice,
      game_day_price: gamedayPrice,
      id
    } = menuItem
    setSelectedItem({
      id,
      name,
      description,
      image,
      advancePrice,
      gamedayPrice
    })
    setOpenMenuDetailsDialog(true)
  }

  const getEffectiveOrderWindow = (selectedPriceWindow, orderWindow) => {
    return selectedPriceWindow || orderWindow
  }

  const priceByOrderWindow = (item, effectiveOrderWindow) => {
    return effectiveOrderWindow.toLowerCase() === ADVANCE_PRICE_WINDOW ? item.advancePrice : item.gamedayPrice
  }

  const priceWindowOverridedByAdmin = (selectedPriceWindow, orderWindow) => ((orderWindow.toLowerCase() === GAMEDAY_PRICE_WINDOW) && (selectedPriceWindow.toLowerCase() === ADVANCE_PRICE_WINDOW))

  const handleAddToCart = (item, quantity, selectedPriceWindow) => {
    const effectiveOrderWindow = getEffectiveOrderWindow(selectedPriceWindow, orderWindow)
    const itemPrice = priceByOrderWindow(item, effectiveOrderWindow)
    const tempCartItem = {
      menuItemId: item.id,
      menu_item_name: item.name,
      unitPrice: itemPrice,
      quantity,
      totalPrice: parseFloat(+itemPrice * quantity).toFixed(2),
      orderWindow: effectiveOrderWindow
    }
    if (isCurrentUserAdmin && priceWindowOverridedByAdmin(selectedPriceWindow, orderWindow)) tempCartItem.force_advance_price = true
    const existingIndex = orderItems.findIndex(cartItem => cartItem.orderWindow.toLowerCase() === tempCartItem.orderWindow.toLowerCase() && cartItem.menuItemId === tempCartItem.menuItemId && cartItem._destroy !== 1)
    const updatedOrderItems = [...orderItems]
    if (existingIndex === -1) {
      updatedOrderItems.push(tempCartItem)
    } else {
      const existingItem = orderItems[existingIndex]
      existingItem.quantity += tempCartItem.quantity
      existingItem.totalPrice = parseFloat(+existingItem.unitPrice * existingItem.quantity).toFixed(2)
      updatedOrderItems[existingIndex] = existingItem
    }
    reportAnalytics({
      eventType: ANALYTICS_ACTION_CART_ADD,
      detail: {
        actionName: ANALYTICS_ACTION_CART_QUICK_ADD,
        actionLocation: ANALYTICS_ACTION_LOCATION_MODAL,
        actionType: ANALYTICS_ACTION_TYPE_CART_MODAL_ADD,
        actionItem: item.name,
        actionItemId: item.id,
        actionQty: quantity,
        actionPrice: itemPrice,
        actionForcedPrice: tempCartItem.force_advance_price || false
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ORDERS_EDIT
    })
    getGoogleAnalytics(itemPrice, quantity, item)
    updateOrderItems(updatedOrderItems)
    setSelectedItem({})
    setOpenMenuDetailsDialog(false)
  }

  const getGoogleAnalytics = (itemPrice, quantity, item) => {
    ADD_PRODUCT_DATA.ecommerce.items = []
    ADD_PRODUCT_DATA.ecommerce.value = 0
    const subtotal = (parseFloat(itemPrice) * quantity)
    ADD_PRODUCT_DATA.ecommerce.items.push({
      item_name: item.name,
      item_id: item.id,
      addType: 'menu_quick_add_to_cart',
      company_selection: '',
      suite_selection: '', // to do fix this
      quantity: quantity,
      price: itemPrice
    })
    ADD_PRODUCT_DATA.addType = ANALYTICS_ACTION_TYPE_CART_QUICK_ADD
    ADD_PRODUCT_DATA.ecommerce.value = subtotal
    reportGoogleAnalytics(ANALYTICS_EVENT_CART_MODAL_ADD, ADD_PRODUCT_DATA)
  }

  return (
    <div className={classes.orderDetailsContainer}>
      <div className={classes.orderDetailsHeaderContainer}>
        <div className={classes.orderNumber}>
          {`Order#${actionBarData.orderId}`}
        </div>
      </div>
      <OrderBasicDetails
        suite={suite}
        companyName={companyName}
        eventName={eventName}
        eventDate={eventDate}
        createdDate={createdDate}
        total={total}
        menuTypeName={menuTypeName}
        paymentMethodName={paymentMethodName}
        paymentStatusName={paymentStatusName}
        submittedBy={submittedBy}
      />
      <InSuiteActionOptions checkoutOptionsSummary={checkoutOptionsSummary} hideParOptions={currentUser.single_event_suite_user} />
      <div className={classes.itemsAndPaymentWrapper}>
        <div className={classes.orderItemsContainerList}>
          <div className={classes.editOrderActionsContainer}>
            <SearchMenuItems fetchMenuItems={fetchMenuItems} handleMenuSelection={handleMenuSelection} />
            <CustomButton
              variant={'contained'}
              disableRipple
              styleClass={classes.addMenuItems}
              label={'Browse Menu Items'}
              color={'primary'}
              clickHandler={addMenuItems}
            />
          </div>
          <OrderItemsListHeader />
          <OrderItemsList
            orderItems={activeOrderItems}
            currentUser={currentUser}
            currentOrderWindow={orderWindow}
            mode={mode}
            handleQuantityChange={handleQuantityChange}
            handleRemoveItemFromCart={handleRemoveItemFromCart}
          />
          <BillSummaryLineItem label={subtotalLabel} value2={formatFloatNumber(billSummary.subTotal)} paymentLineItem />
        </div>
        <div className={classes.paymentSummaryContainer}>
          <BillSummaryLineItem label={taxLabel} value1={'8.875%'} value2={`$${billSummary.tax}`} />
          <BillSummaryLineItem label={gratuityLabel} value1={`${orderSummary.gratuityPercentage}%`} value2={`$${billSummary.gratuity}`} />
          <BillSummaryLineItem label={tipFromTerminalLabel} value2={tipFromTerminal} />
          <BillSummaryLineItem label={totalLabel} value2={`$${billSummary.total}`} paymentLineItem />
          <div className={classes.buttonsContainer}>
            <CustomButton
              variant={'outlined'}
              disableRipple
              styleClass={classes.cancelOrderEditing}
              label={'Cancel'}
              color={'primary'}
              clickHandler={cancelOrderEdit}
            />
            <CustomButton
              variant={'contained'}
              disableRipple
              styleClass={classes.proceedToCheckout}
              label={'Proceed To Checkout'}
              color={'primary'}
              clickHandler={proceedToCheckout}
            />
          </div>
        </div>
      </div>
      {openMenuDetailsDialog && <MenuItemDetails openDialog={openMenuDetailsDialog} menuItem={selectedItem} handleDialogClose={onDialogClose} handleAddToCart={handleAddToCart} orderWindow={orderWindow} currentUser={currentUser} /> }
    </div>
  )
}

EditOrderDetail.propTypes = {
  orderItems: PropTypes.array,
  order: PropTypes.object,
  fetchOrder: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  orderByIdData: PropTypes.object,
  orderWindow: PropTypes.string,
  billDetails: PropTypes.object,
  companyId: PropTypes.number,
  suiteId: PropTypes.number,
  menuTypeId: PropTypes.number,
  userId: PropTypes.number,
  eventId: PropTypes.number,
  updateOrderItems: PropTypes.func,
  currentUser: PropTypes.object,
  eventDetails: PropTypes.object
}

const mapStateToProps = ({
  orderReducer,
  checkoutData: {
    eventDetails,
    orderItems,
    orderWindow,
    billDetails,
    companyId,
    eventId,
    suiteId,
    userId,
    menuTypeId
  },
  authReducer: { user: currentUser }
}) => ({
  orderByIdData: orderReducer.OrderByIdData,
  orderItems,
  orderWindow,
  billDetails,
  companyId,
  eventId,
  suiteId,
  userId,
  menuTypeId,
  currentUser,
  eventDetails
})

const mapDispatchToProps = {
  fetchOrder: fetchOrderById,
  updateOrderItems: storeOrderItems
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(EditOrderDetail))
)
