import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '../../elements/Dialog/Dialog'
import FreedomPayIframe from '../ManageSuiteHolderUser/FreedomPayIframe'
import AccountUpdateContent from '../AccountUpdate/AccountUpdateContent'
import CardNameUpdateForm from '../ManageSuiteHolderUser/CardNameUpdateForm'
import AddCreditCardTitle from './AddCreditCardTitle'
import styles from './AddCreditCard.css'

const AddCreditCard = ({
  addCardErr,
  classes,
  clearAddCreditCardError,
  closeCCModal,
  closeFeedomPayModal,
  freedompayIframe,
  handleAddCardDialogClose,
  openAddCardDialog,
  paymentDetails,
  removeFreedomPayIframe,
  showFormToNameCreditCard,
  suiteHolderUserId,
  tokenizeCreditCard,
  stopReload = false
}) => {
  const [iframeRequestInProgress, setIframeRequestInProgress] = useState(false)

  const closeModal = () => {
    if (!addCardErr) {
      if (!stopReload) window?.location?.reload()
      clearModals()
    } else {
      clearModals()
    }
  }

  const clearModals = () => {
    closeFeedomPayModal()
    removeFreedomPayIframe()
    clearAddCreditCardError()
    setIframeRequestInProgress(true)
  }
  const handleShowForm = () => {
    if (!showFormToNameCreditCard && !addCardErr) {
      setIframeRequestInProgress(true)
    }
  }

  useEffect(() => {
    handleShowForm()
    if (freedompayIframe) {
      setIframeRequestInProgress(false)
    }
  }, [freedompayIframe])

  const freedompayIframeFx = () => <>
    <FreedomPayIframe
      iframeRequestInProgress={iframeRequestInProgress}
      freedompayIframe={freedompayIframe}
      removeFreedomPayIframe={removeFreedomPayIframe}
      tokenizeCreditCard={tokenizeCreditCard}
      suiteHolderUserId={suiteHolderUserId}
      showTitle={false}
      showFormToNameCreditCard={showFormToNameCreditCard}
      addCardErr={addCardErr}
      clearAddCreditCardError={clearAddCreditCardError}
    />
    {
      addCardErr &&
      <AccountUpdateContent
        classes={classes}
        accountUpdateErr={addCardErr} />
    }
    {
      showFormToNameCreditCard &&
      <CardNameUpdateForm
        classes={classes}
        suiteHolderUserId={suiteHolderUserId}
        paymentDetails={paymentDetails}
        stopEventDispatchForClientUser
        closeCCModal={closeCCModal} />
    }
  </>

  return (
    <Dialog
      openDialog={openAddCardDialog}
      dialogTitle={<AddCreditCardTitle classes={classes} closeModal={closeModal} />}
      dialogContent={freedompayIframeFx()}
      classes={{
        genericPaperStyles: classes.genericPaperStyles,
        genericDialogActionRoot: classes.genericDialogActionRoot,
        genericDialogContentRoot: classes.genericDialogContentRoot,
        genericDialogTitleRoot: classes.genericDialogTitleRoot
      }}
      onDialogClose={handleAddCardDialogClose}
    />
  )
}

AddCreditCard.propTypes = {
  addCardErr: PropTypes.object,
  clearAddCreditCardError: PropTypes.func,
  classes: PropTypes.object,
  closeCCModal: PropTypes.func,
  closeFeedomPayModal: PropTypes.func,
  freedompayIframe: PropTypes.string,
  handleAddCardDialogClose: PropTypes.func,
  openAddCardDialog: PropTypes.bool,
  tokenizeCreditCard: PropTypes.func,
  paymentDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  removeFreedomPayIframe: PropTypes.func,
  showFormToNameCreditCard: PropTypes.bool,
  suiteHolderUserId: PropTypes.string,
  stopReload: PropTypes.bool
}

export default withStyles(styles)(AddCreditCard)
