import React, { Suspense, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  activateSuiteHolderUser,
  addNewUser,
  addNewCreditCardToPaymentDetails,
  addSuiteHolderUser,
  closeDialog,
  deActivateSuiteHolderUser,
  deletePaymentCardFromStore,
  deleteCreditCardFromUser,
  fetchSuiteHolderUserById,
  fetchSuiteHolderUsers,
  removeAddedCardFromStore,
  setSuiteHolderUserDataFromLocalStorage,
  setAddedCreditCard,
  updateSuiteHolderUser
} from '../../store/suiteHolderUsers/actions'
import {
  storeUserCreditCards,
  resetCreditCardAddedMessage,
  initiateAddCreditCard,
  clearAddCreditCardError,
  hideUpdateCardNameForm
} from '../../store/creditCard/actions'

import SuiteHolderUsersFilterBar from '../../elements/SuiteHolderUsersFilterBar/SuiteHolderUsersFilterBar'
import DataContainer from '../../elements/DataContainer/DataContainer'
import FilterBar from '../../elements/FilterBar/FilterBar'
import ManageSuiteHolderUser from '../../Dialogs/ManageSuiteHolderUser/ManageSuiteHolderUser'
import QuickNotice from '../../elements/QuickNotice/QuickNotice'
import { currentUserIsClient } from '../../store/helpers/userRoleManager'
import { validateUser } from '../../utils/suitesUserFormValidator'
import {
  defaultPage,
  defaultLimit,
  defaultTotalPages,
  rowsPerPageOptions
} from '../../constants/pagination'
import {
  ctaLabel,
  rowsFor,
  resourceName,
  suiteHolderUserProperties,
  styles
} from '../../constants/suiteHolderUsers'
import {
  ANALYTICS_ACTION_TYPE_ADD_USER,
  ANALYTICS_ACTION_ADD_USER,
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_ACTION_LOCATION_BODY,
  ANALYTICS_PAGE_TYPE_CLIENT_USERS
} from '../../constants/analytics'
import { reportAnalytics } from '../../utils/analytics'
const LazyTable = React.lazy(() => import('../../elements/Table/Table'))

const SuiteHolderUsersList = ({
  addNewSuiteHolderUser,
  activateSuiteHolderUserStatus,
  addNewCreditCardToPaymentDetails,
  clearAddCreditCardError,
  closeSuiteHolderUserDialog,
  companies,
  currentUser,
  companyId,
  cardDeletionErr,
  creditCardAddedMessage,
  deActivateSuiteHolderUserStatus,
  deleteCreditCardFromUser,
  deletePaymentCardFromStore,
  editSuiteHolderUserById,
  fetchItems,
  filters,
  hideUpdateCardNameForm,
  initiateAddCreditCard,
  limit: rowsPerPage = defaultLimit,
  page = defaultPage,
  rolesList,
  removeAddedCardFromStore,
  rows,
  resetCreditCardAddedMessage,
  suiteHolderUserToProcess,
  sortParams,
  saveNewSuiteHolderUser,
  setSuiteHolderUserDataFromLocalStorage,
  setAddedCreditCard,
  suiteHolderUserCreditCard,
  storeUserCreditCards,
  showCreditCardAddedMessage,
  totalPages = defaultTotalPages,
  updateExistingSuiteHolderUser
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [currentSuiteHolderUser, setCurrentSuiteHolderUser] = useState(null)
  const [mode, setMode] = useState(null)
  const [suites, setSuites] = useState(null)
  const [message, setMessage] = useState('')
  const [paymentDetails, setPaymentDetails] = useState(null)
  const [validationError, setValidationError] = useState('')
  const [newCreditCards, setNewCreditCards] = useState(null)
  const [cardName, setCardName] = useState(null)

  const currentCompanyId = () => {
    return currentUserIsClient(currentUser) ? currentUser.company_id : companyId
  }

  useEffect(() => {
    if (!rows) {
      fetchItems(companyId, {})
    }
  }, [rows, fetchItems, companyId])

  useEffect(() => {
    if (companyId) {
      fetchItems(companyId, {})
    }
  }, [companyId, fetchItems])

  useEffect(() => {
    if (showCreditCardAddedMessage) {
      addNewCreditCardToPaymentDetails(suiteHolderUserCreditCard, cardName)
      setTimeout(() => {
        setCardName(null)
        resetCreditCardAddedMessage()
        setNewCreditCards(null)
        removeAddedCardFromStore(null)
      }, 2000)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCreditCardAddedMessage, resetCreditCardAddedMessage])

  useEffect(() => {
    if (suiteHolderUserToProcess) {
      const {
        suiteHolderUser,
        paymentDetails,
        suitesList,
        mode,
        dialogOpenStatus,
        message
      } = suiteHolderUserToProcess

      setMode(mode)
      setCurrentSuiteHolderUser(suiteHolderUser)
      setSuites(suitesList)
      setPaymentDetails(paymentDetails)
      setOpenDialog(dialogOpenStatus)
      if (message) {
        setMessage(message)
        const timer1 = setTimeout(() => setMessage(''), 2000)
        return () => {
          clearTimeout(timer1)
        }
      }
    }
  }, [suiteHolderUserToProcess])

  const handleChangePage = (event, page) => {
    fetchItems(companyId, filters, page, rowsPerPage)
  }

  const handleChangeRowsPerPage = event => {
    fetchItems(companyId, filters, defaultPage, +event.target.value)
  }

  const handleSorting = (orderBy, sortOrder = 'desc') => {
    const sort = { sortBy: orderBy, order: sortOrder }
    fetchItems(companyId, filters, page, rowsPerPage, sort)
  }

  const handleCreditCardReset = () => {
    clearAddCreditCardError()
    hideUpdateCardNameForm()
  }

  const editSuiteHolderUser = (user) => {
    handleCreditCardReset()
    editSuiteHolderUserById(companies, rolesList, user.id)
  }

  const addSuiteHolderUserHandler = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_ADD_USER,
        actionType: ANALYTICS_ACTION_TYPE_ADD_USER,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionSuiteHolderAdminId: currentUser.id,
        actionCompanyId: companyId
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_USERS
    })
    addNewSuiteHolderUser(companies, rolesList)
  }

  const settingsMenuBuilder = item => [{
    id: item.id,
    label: 'Edit',
    action: editSuiteHolderUser
  }]

  const handleDialogClose = () => {
    let suiteChangesSaved = true
    const options = [...suites]

    options.map(option => {
      if (typeof (option.is_saved) === 'boolean' && !option.is_saved) {
        suiteChangesSaved = false
      }
    })
    if (suiteChangesSaved) {
      setValidationError('')
      closeSuiteHolderUserDialog(companies, rolesList)
    } else {
      setValidationError('Please Save your changes before closing the Dialog')
    }
  }

  const handleFormSubmission = () => {
    const { id } = currentSuiteHolderUser
    if (id) {
      updateExistingSuiteHolderUser(currentSuiteHolderUser, suites, companies, paymentDetails)
    } else {
      saveNewSuiteHolderUser(currentSuiteHolderUser, companies, suites, paymentDetails)
    }
  }

  const saveSuiteHolderUser = () => {
    const formValidationMessage = validateUser(currentSuiteHolderUser)
    setValidationError(formValidationMessage)
    if (!formValidationMessage) {
      fetchItems(companyId, filters, page, rowsPerPage)
      handleFormSubmission()
    }
  }

  const handleUserStatus = () => {
    const { webStatus = false, id } = currentSuiteHolderUser
    if (webStatus) {
      deActivateSuiteHolderUserStatus(id)
    } else {
      activateSuiteHolderUserStatus(id)
    }
  }

  const setFirstName = (firstName) => {
    const updatedSuitesHolderUser = {
      ...currentSuiteHolderUser,
      firstName
    }
    setCurrentSuiteHolderUser(updatedSuitesHolderUser)
  }

  const setLastName = (lastName) => {
    const updatedSuitesHolderUser = {
      ...currentSuiteHolderUser,
      lastName
    }
    setCurrentSuiteHolderUser(updatedSuitesHolderUser)
  }

  const setEmail = (email) => {
    const updatedSuitesHolderUser = {
      ...currentSuiteHolderUser,
      email
    }
    setCurrentSuiteHolderUser(updatedSuitesHolderUser)
  }

  const setSESUser = (event) => {
    let sesUser = Boolean(event.target.value) || true
    if (event.target.value === 'false') { sesUser = false }
    const updatedSuitesHolderUser = {
      ...currentSuiteHolderUser,
      sesUser
    }
    setCurrentSuiteHolderUser(updatedSuitesHolderUser)
  }

  const setContactNumber = (contactNumber) => {
    const updatedSuitesHolderUser = {
      ...currentSuiteHolderUser,
      contactNumber
    }
    setCurrentSuiteHolderUser(updatedSuitesHolderUser)
  }

  const setRole = (event) => {
    const userRoleId = event.target.value
    const selectedRoleId = rolesList.filter(role => role.id === userRoleId)[0].id
    const updatedSuitesHolderUser = {
      ...currentSuiteHolderUser,
      roleId: selectedRoleId
    }
    setCurrentSuiteHolderUser(updatedSuitesHolderUser)
  }

  const handleSuitesUpdate = (event) => {
    const suiteId = event.currentTarget.id
    const options = [...suites]
    const currentCheckboxIndex = options.findIndex(({ id, suite_id: userSuiteId }) => {
      const selectedId = id || userSuiteId
      return (String(selectedId) === String(suiteId))
    })
    const selectedSuite = options[currentCheckboxIndex]
    options[currentCheckboxIndex].is_assigned = !selectedSuite.is_assigned
    if (!options[currentCheckboxIndex].is_assigned || !!currentSuiteHolderUser.sesUser) {
      options[currentCheckboxIndex].is_saved = selectedSuite.is_saved ? !selectedSuite.is_saved : false
      options[currentCheckboxIndex].can_use_invoice = false
    }
    setSuites(options)
  }

  const handleSuitesInvoiceUpdate = (event) => {
    const suiteId = event.currentTarget.id
    const options = [...suites]
    const currentCheckboxIndex = options.findIndex(({ id, suite_id: userSuiteId }) => {
      const selectedId = id || userSuiteId
      return (String(selectedId) === String(suiteId))
    })
    const selectedSuite = options[currentCheckboxIndex]
    options[currentCheckboxIndex].can_use_invoice = !selectedSuite.can_use_invoice
    setSuites(options)
  }

  const handleCardDelete = (event) => {
    const { id } = event.currentTarget
    const { paymentDetails } = suiteHolderUserToProcess
    const { credit_card_details: creditCardDetails = [] } = paymentDetails
    const deletedCard = creditCardDetails[id]
    if (deletedCard && deletedCard.id) {
      deleteCreditCardFromUser(deletedCard)
    } else {
      deletePaymentCardFromStore(id)
    }
  }

  useEffect(() => {
    const suiteHolderUserToProcessFromLocalStorage = JSON.parse(localStorage.getItem('suiteHolderUserToProcess'))
    if (suiteHolderUserToProcessFromLocalStorage) {
      const { suiteHolderUser } = suiteHolderUserToProcessFromLocalStorage
      setSuiteHolderUserDataFromLocalStorage(suiteHolderUserToProcessFromLocalStorage)
      localStorage.removeItem('suiteHolderUserToProcess')

      const cardObject = JSON.parse(localStorage.getItem('addedCreditCard'))
      if (cardObject) {
        cardObject.suiteHolderUserId = suiteHolderUser.id
        localStorage.removeItem('addedCreditCard')
        setAddedCreditCard(cardObject)
      }
    }
  })

  useEffect(() => {
    if (suiteHolderUserCreditCard) {
      setNewCreditCards(suiteHolderUserCreditCard)
    }
  }, [suiteHolderUserCreditCard])

  const fetchSuiteItems = (filters, page, limit, sortParams) => {
    fetchItems(companyId, filters, defaultPage, limit, sortParams)
  }

  return (
    <div>
      <DataContainer>
        {
          message &&
          <QuickNotice message={message} />
        }
        <FilterBar fetchItems={fetchSuiteItems} page={page} limit={rowsPerPage} sortParams={sortParams} filters={filters} styles={styles}>
          <SuiteHolderUsersFilterBar
            ctaLabel={ctaLabel}
            addSuiteHolderUserHandler={addSuiteHolderUserHandler}
          />
        </FilterBar>
        <Suspense fallback={<div>Loading...</div>}>
          {rows && <LazyTable
            properties={suiteHolderUserProperties}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            sortParams={sortParams}
            handleSorting={handleSorting}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            totalPages={totalPages}
            rowsFor={rowsFor}
            resourceName={resourceName}
            settingsMenuBuilder={settingsMenuBuilder}
          />}
        </Suspense>
        {openDialog && <ManageSuiteHolderUser
          companies={companies}
          companyId={currentCompanyId()}
          creditCardAddedMessage={creditCardAddedMessage}
          cardDeletionErr={cardDeletionErr}
          handleSuitesUpdate={handleSuitesUpdate}
          handleSuitesInvoiceUpdate={handleSuitesInvoiceUpdate}
          handleCardDelete={handleCardDelete}
          handleUserStatus={handleUserStatus}
          handleDialogClose={handleDialogClose}
          mode={mode}
          newCreditCards={newCreditCards}
          openDialog={openDialog}
          paymentDetails={paymentDetails}
          rolesList={rolesList}
          setFirstName={setFirstName}
          saveSuiteHolderUser={saveSuiteHolderUser}
          suites={suites}
          setLastName={setLastName}
          setEmail={setEmail}
          setContactNumber={setContactNumber}
          setRole={setRole}
          setSESUser={setSESUser}
          suiteHolderUser={currentSuiteHolderUser}
          showCreditCardAddedMessage={showCreditCardAddedMessage}
          validationError={validationError}
        />}
      </DataContainer>
    </div>

  )
}

SuiteHolderUsersList.propTypes = {
  activateSuiteHolderUserStatus: PropTypes.func,
  addNewCreditCardToPaymentDetails: PropTypes.func,
  addNewSuiteHolderUser: PropTypes.func,
  companies: PropTypes.array,
  companyId: PropTypes.number,
  cardDeletionErr: PropTypes.object,
  creditCardAddedMessage: PropTypes.string,
  currentUser: PropTypes.object,
  clearAddCreditCardError: PropTypes.func,
  closeSuiteHolderUserDialog: PropTypes.func,
  deleteCreditCardFromUser: PropTypes.func,
  deletePaymentCardFromStore: PropTypes.func,
  deActivateSuiteHolderUserStatus: PropTypes.func,
  editSuiteHolderUserById: PropTypes.func,
  filters: PropTypes.object,
  fetchItems: PropTypes.func,
  hideUpdateCardNameForm: PropTypes.func,
  initiateAddCreditCard: PropTypes.func,
  limit: PropTypes.number,
  page: PropTypes.number,
  rows: PropTypes.array,
  rolesList: PropTypes.array,
  removeAddedCardFromStore: PropTypes.func,
  resetCreditCardAddedMessage: PropTypes.func,
  setSuiteHolderUserDataFromLocalStorage: PropTypes.func,
  saveNewSuiteHolderUser: PropTypes.func,
  setAddedCreditCard: PropTypes.func,
  storeUserCreditCards: PropTypes.func,
  suiteHolderUserCreditCard: PropTypes.object,
  showCreditCardAddedMessage: PropTypes.bool,
  sortParams: PropTypes.object,
  suiteHolderUserToProcess: PropTypes.object,
  totalPages: PropTypes.number,
  updateExistingSuiteHolderUser: PropTypes.func
}

const mapStateToProps = state => {
  const currentUser = state.authReducer.user
  const rolesList = state.staticData?.roles?.suite_holder_user
  const companies = state.staticData.companies
  let currentCompany
  if (currentUserIsClient(currentUser)) {
    currentCompany = companies[0]
  } else {
    const { companyDetails = {} } = state.companiesData
    currentCompany = companies.find(company => company.eatec_id === companyDetails.oracleId)
  }

  const {
    showCreditCardAddedMessage,
    creditCardAddedMessage
  } = state.accountInfo
  return {
    ...state.suiteHolderUsersData.suiteHolderUsersList,
    currentUser,
    companies: [currentCompany],
    rolesList,
    showCreditCardAddedMessage,
    creditCardAddedMessage,
    suites: state.companiesData.suites,
    suiteHolderUserToProcess: state.suiteHolderUsersData.suiteHolderUserToProcess,
    suiteHolderUserCreditCard: state.suiteHolderUsersData.suiteHolderUserCreditCard,
    cardDeletionErr: state.suiteHolderUsersData.cardDeletionErr
  }
}
const mapDispatchToProps = {
  fetchItems: fetchSuiteHolderUsers,
  editSuiteHolderUserById: fetchSuiteHolderUserById,
  addNewSuiteHolderUser: addNewUser,
  clearAddCreditCardError,
  closeSuiteHolderUserDialog: closeDialog,
  activateSuiteHolderUserStatus: activateSuiteHolderUser,
  deActivateSuiteHolderUserStatus: deActivateSuiteHolderUser,
  saveNewSuiteHolderUser: addSuiteHolderUser,
  updateExistingSuiteHolderUser: updateSuiteHolderUser,
  setSuiteHolderUserDataFromLocalStorage,
  storeUserCreditCards,
  setAddedCreditCard,
  removeAddedCardFromStore,
  resetCreditCardAddedMessage,
  addNewCreditCardToPaymentDetails,
  deletePaymentCardFromStore,
  deleteCreditCardFromUser,
  initiateAddCreditCard,
  hideUpdateCardNameForm
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SuiteHolderUsersList)
)
