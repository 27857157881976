
import {
  reportAnalytics
} from '../../utils/analytics'
import {
  ANALYTICS_ACTION_FILTER_SELECTION,
  ANALYTICS_ACTION_LOCATION_BODY,
  ANALYTICS_ACTION_SUBMIT_CLICK,
  ANALYTICS_ACTION_FILTER_CLICK,
  ANALYTICS_ACTION_TYPE_PAR_INSTRUCTIONS,
  ANALYTICS_PAGE_TYPE_CHECKOUT,
  ANALYTICS_ACTION_TYPE_PAYMENT_METHOD,
  ANALYTICS_ACTION_TYPE_ITEM_ADDITIONS_BY,
  ANALYTICS_ACTION_TYPE_SIGNATURE_OPTIONS,
  ANALYTICS_ACTION_PLACE_ORDER,
  ANALYTICS_ACTION_TYPE_ORDER_SUBMIT
} from '../../constants/analytics'
import {
  ITEM_ADDTION_BY_OPTIONS,
  PAYMENT_METHODS,
  PAR_INSTRUCTION_OPTIONS,
  SIGNATURE_OPTIONS
} from '../../constants/checkout'

import { eventEndpoints, suitesEndpoints, suiteHolderUsersEndpoints, orderEndpoints } from '../../constants/apiConfig'
import {
  STORE_MENU_CATALOG_PREREQUISITES,
  STORE_ORDER_ITEMS,
  STORE_CHECKOUT_FLOW_EVENT_DATA,
  STORE_CHECKOUT_FLOW_USER_PAYMENT_DATA,
  STORE_CHECKOUT_FLOW_SUITE_DATA,
  STORE_CHECKOUT_PAYMENT_DATA,
  STORE_CHECKOUT_PAYMENT_METHOD_DATA,
  RESET_CHECKOUT_DATA,
  STORE_CHECKOUT_VALIDATION_DATA,
  STORE_CHECKOUT_PAYMENT_OPTIONS_DATA,
  SHOW_CHECKOUT_SUCCESS_MESSAGE,
  SHOW_CHECKOUT_ERROR_MESSAGE,
  RESET_PAYMENT_OPTION_DATA,
  RESET_ORDERS_DATA
} from '../actionTypes'

import { getItemsList } from '../helpers/apiClient'
import { logoutUser } from '../authentication/actions'
import store, { history } from '../store'
import { currentUserIsClient } from '../helpers/userRoleManager'

const NO_IN_SUITE_ITEM_ADDITIONS = 3
const CREDIT_CARD_PAYMENT_METHOD = 1
const CHARGE_ADDITIONS_TO_CREDIT_CARD = 3

export const storeMenuCatalogPrerequisites = (menuCatalogPrerequisites) => dispatch => {
  const { companyId, suiteId } = menuCatalogPrerequisites
  const {
    suites,
    companies
  } = store.getState().staticData
  const suite = suites.find(suite => +suite.id === +suiteId)
  const company = companies.find(company => +company.id === +companyId)
  dispatch({
    type: STORE_MENU_CATALOG_PREREQUISITES,
    payload: { ...menuCatalogPrerequisites, company, suite }
  })
}

export const storeOrderItems = (orderItems) => dispatch => {
  const {
    usedCreditCards,
    paymentMethod
  } = store.getState().checkoutData
  dispatch({
    type: STORE_ORDER_ITEMS,
    payload: { orderItems, usedCreditCards, paymentMethod }
  })
}

export const getEventDetailsWithOrderWindow = (eventId, menuTypeId) => dispatch => {
  const { method, url } = eventEndpoints.orderWindow
  const urlWithId = `${url.replace(':eventId', eventId)}?menu_type_id=${menuTypeId}`
  getItemsList(method, urlWithId)
    .then(res => {
      const { data = {} } = res
      const { event_details: eventDetails, order_window: orderWindow } = data
      dispatch({
        type: STORE_CHECKOUT_FLOW_EVENT_DATA,
        payload: { eventDetails, orderWindow }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const getUserPaymentDetails = (userId, suiteId) => dispatch => {
  const { method, url } = suiteHolderUsersEndpoints.paymentDetails
  const urlWithId = `${url.replace(':suiteHolderUserId', userId)}`
  const {
    user: currentUser = {}
  } = store.getState().authReducer
  getItemsList(method, urlWithId)
    .then(res => {
      const { data = {} } = res
      const { payment_details: paymentDetails } = data
      let payload = {}
      payload = { userPaymentDetails: paymentDetails }
      if (currentUserIsClient(currentUser)) {
        const { companies_suites_details: companiesSuitesDetails = [] } = paymentDetails
        const companyId = companiesSuitesDetails?.filter(suite => suite.suite_id === +suiteId)
        const { companies_suite_id: companiesSuiteId } = companyId[0]
        payload = { ...payload, companiesSuiteId }
      }
      dispatch({
        type: STORE_CHECKOUT_FLOW_USER_PAYMENT_DATA,
        payload
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const getSuiteDetails = (suiteId) => dispatch => {
  const { url, method } = suitesEndpoints.editById
  const urlWithId = `${url.replace(':suiteId', suiteId)}`
  getItemsList(method, urlWithId)
    .then(res => {
      const { data = {} } = res
      const { suite } = data
      dispatch({
        type: STORE_CHECKOUT_FLOW_SUITE_DATA,
        payload: { suiteDetails: suite, suiteDisplayName: suite.display_name }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const setPaymentMethod = (paymentMethod) => dispatch => {
  reportAnalytics({
    eventType: ANALYTICS_ACTION_FILTER_CLICK,
    detail: {
      actionName: ANALYTICS_ACTION_FILTER_SELECTION,
      actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
      actionType: ANALYTICS_ACTION_TYPE_PAYMENT_METHOD,
      actionFiltertype: PAYMENT_METHODS[paymentMethod]
    },
    pageType: ANALYTICS_PAGE_TYPE_CHECKOUT
  })
  const {
    usedCreditCards,
    itemAdditionsBy,
    billDetails: {
      billSummary = {}
    } = {}
  } = store.getState().checkoutData
  const { total } = billSummary

  let inSuiteAdditionsCharge = null
  if (itemAdditionsBy === NO_IN_SUITE_ITEM_ADDITIONS) {
    inSuiteAdditionsCharge = null
  } else {
    if (paymentMethod === CREDIT_CARD_PAYMENT_METHOD) {
      inSuiteAdditionsCharge = CHARGE_ADDITIONS_TO_CREDIT_CARD
    }
  }
  dispatch({
    type: STORE_CHECKOUT_PAYMENT_METHOD_DATA,
    payload: { paymentMethod, usedCreditCards, orderTotal: total, inSuiteAdditionsCharge }
  })
}

export const setItemAdditionsBy = (itemAdditionsBy) => dispatch => {
  const payload = { itemAdditionsBy }
  const {
    paymentMethod
  } = store.getState().checkoutData
  reportAnalytics({
    eventType: ANALYTICS_ACTION_FILTER_CLICK,
    detail: {
      actionName: ANALYTICS_ACTION_FILTER_SELECTION,
      actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
      actionType: ANALYTICS_ACTION_TYPE_ITEM_ADDITIONS_BY,
      actionFiltertype: ITEM_ADDTION_BY_OPTIONS[itemAdditionsBy]
    },
    pageType: ANALYTICS_PAGE_TYPE_CHECKOUT
  })
  if (itemAdditionsBy === NO_IN_SUITE_ITEM_ADDITIONS) {
    payload.inSuiteAdditionsCharge = null
  } else {
    if (paymentMethod === CREDIT_CARD_PAYMENT_METHOD) {
      payload.inSuiteAdditionsCharge = CHARGE_ADDITIONS_TO_CREDIT_CARD
    }
  }
  dispatch({
    type: STORE_CHECKOUT_PAYMENT_OPTIONS_DATA,
    payload
  })
}

export const setSignatureReguired = (signatureRequired) => dispatch => {
  reportAnalytics({
    eventType: ANALYTICS_ACTION_FILTER_CLICK,
    detail: {
      actionName: ANALYTICS_ACTION_FILTER_SELECTION,
      actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
      actionType: ANALYTICS_ACTION_TYPE_SIGNATURE_OPTIONS,
      actionFiltertype: SIGNATURE_OPTIONS[signatureRequired]
    },
    pageType: ANALYTICS_PAGE_TYPE_CHECKOUT
  })
  dispatch({
    type: STORE_CHECKOUT_PAYMENT_OPTIONS_DATA,
    payload: { signatureRequired }
  })
}

export const setLiquorCabinetAccess = (liquorCabinetAccess) => dispatch => {
  reportAnalytics({
    eventType: ANALYTICS_ACTION_FILTER_CLICK,
    detail: {
      actionName: ANALYTICS_ACTION_FILTER_SELECTION,
      actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
      actionType: ANALYTICS_ACTION_TYPE_PAR_INSTRUCTIONS,
      actionFiltertype: PAR_INSTRUCTION_OPTIONS[liquorCabinetAccess]
    },
    pageType: ANALYTICS_PAGE_TYPE_CHECKOUT
  })

  dispatch({
    type: STORE_CHECKOUT_PAYMENT_OPTIONS_DATA,
    payload: { liquorCabinetAccess }
  })
}

export const hostNameChangeHandler = (hostName) => dispatch => {
  dispatch({
    type: STORE_CHECKOUT_PAYMENT_OPTIONS_DATA,
    payload: { hostName }
  })
}

export const orderNameChangeHandler = (orderName) => dispatch => {
  dispatch({
    type: STORE_CHECKOUT_PAYMENT_OPTIONS_DATA,
    payload: { orderName }
  })
}

export const orderCommentChangeHandler = (orderComment) => dispatch => {
  dispatch({
    type: STORE_CHECKOUT_PAYMENT_OPTIONS_DATA,
    payload: { orderComment }
  })
}

export const inSuiteAdditionsChargeHandler = (inSuiteAdditionsCharge) => dispatch => {
  dispatch({
    type: STORE_CHECKOUT_PAYMENT_OPTIONS_DATA,
    payload: { inSuiteAdditionsCharge }
  })
}

export const storeCreditCardPaymentDetails = (creditCards) => dispatch => {
  const {
    billDetails: {
      billSummary = {}
    } = {}
  } = store.getState().checkoutData
  const { total } = billSummary
  dispatch({
    type: STORE_CHECKOUT_PAYMENT_DATA,
    payload: { usedCreditCards: creditCards, orderTotal: total }
  })
}

const formatCardDataForRequest = (creditCards) => {
  return creditCards.map(card => {
    const { id, amount, isPrimary, cardType } = card
    return {
      id,
      amount,
      is_primary: isPrimary,
      card_type: cardType
    }
  })
}

const processOrderItemsForAnalytics = (orderItems) => {
  return orderItems.map(orderItem => {
    return {
      productInfo: {
        productID: orderItem.menuItemId,
        productName: orderItem.menu_item_name,
        productPrice: orderItem.unitPrice,
        productTotalPrice: orderItem.totalPrice,
        productQty: orderItem.quantity,
        recommendedItem: orderItem.recommendedItem || false
      }
    }
  }, [])
}

export const placeOrder = () => dispatch => {
  const {
    billDetails: {
      billSummary
    } = {},
    eventId,
    companiesSuiteId,
    hostName,
    orderComment,
    menuTypeId,
    orderName,
    itemAdditionsBy,
    signatureRequired,
    paymentMethod,
    orderItems,
    usedCreditCards,
    userId,
    liquorCabinetAccess,
    inSuiteAdditionsCharge,
    orderId
  } = store.getState().checkoutData

  try {
    if (!hostName || !paymentMethod || !signatureRequired || !itemAdditionsBy) {
      dispatch({
        type: STORE_CHECKOUT_VALIDATION_DATA,
        payload: { showValidationErrorMessage: true }
      })
      return
    }

    if (paymentMethod && paymentMethod === 1 && itemAdditionsBy !== NO_IN_SUITE_ITEM_ADDITIONS && !inSuiteAdditionsCharge) {
      dispatch({
        type: STORE_CHECKOUT_VALIDATION_DATA,
        payload: { showValidationErrorMessage: true }
      })
      return
    }

    const orderItemList = orderItems.map(item => {
      const tempItem = {
        menu_item_id: item.menuItemId,
        quantity: item.quantity,
        id: item.id,
        _destroy: item._destroy || 0,
        force_advance_price: item.force_advance_price || false
      }
      if (!tempItem.id) { delete tempItem.id }
      return tempItem
    })
    const {
      user_details: {
        id: submittedBy
      }
    } = store.getState().staticData

    const formattedOrderData = {
      event_id: eventId,
      companies_suite_id: companiesSuiteId,
      name: orderName,
      comment: orderComment,
      submitted_by: submittedBy,
      submitted_for: userId,
      order_items_attributes: JSON.stringify(orderItemList),
      host_name: hostName,
      menu_type_id: menuTypeId,
      item_additions_by: itemAdditionsBy,
      liquor_cabinet_access: liquorCabinetAccess,
      signature_required: signatureRequired,
      order_bill_attributes: {
        gratuity_percentage: 18,
        gratuity_for_attendant: 0.00,
        gratuity_for_attendant_in_percentage: 0,
        gratuity_for_attendant_type: 2,
        payment_method: paymentMethod,
        payment_details: paymentMethod === 1 ? JSON.stringify({ credit_cards: formatCardDataForRequest(usedCreditCards) }) : {},
        in_suite_addition_charge: inSuiteAdditionsCharge
      }
    }
    let endpointData
    if (orderId) {
      endpointData = orderEndpoints.updateOrder(orderId)
    } else {
      endpointData = orderEndpoints.placeOrder
    }
    const { method, url } = endpointData
    reportAnalytics({
      eventType: ANALYTICS_ACTION_SUBMIT_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_PLACE_ORDER,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionType: ANALYTICS_ACTION_TYPE_ORDER_SUBMIT,
        actionOrderId: orderId,
        actionItems: processOrderItemsForAnalytics(orderItems),
        actionBillSummary: billSummary
      },
      pageType: ANALYTICS_PAGE_TYPE_CHECKOUT
    })

    getItemsList(method, url, { order: formattedOrderData })
      .then(res => {
        const {
          data: {
            message: successMessage,
            order: placedOrder,
            success: apiStatus
          } = {}
        } = res
        dispatch({
          type: RESET_ORDERS_DATA,
          payload: {}
        })
        dispatch({
          type: SHOW_CHECKOUT_SUCCESS_MESSAGE,
          payload: { updateActiveOrders: true, showOrderSuccessMessage: true, orderSuccessResponse: { successMessage, placedOrder, apiStatus } }
        })
      })
      .catch(error => {
        if (error.response.status === 401) {
          dispatch(logoutUser(history))
        } else {
          dispatch({
            type: SHOW_CHECKOUT_ERROR_MESSAGE,
            payload: { showOrderErrorMessage: true, orderErrorResponse: error }
          })
        }
      })
  } catch (error) {
    console.log(error)
    dispatch({
      type: SHOW_CHECKOUT_ERROR_MESSAGE,
      payload: { showOrderErrorMessage: true, orderErrorResponse: error }
    })
  }
}

export const resetCheckoutData = () => dispatch => {
  dispatch({
    type: RESET_CHECKOUT_DATA,
    payload: {}
  })
}

export const resetPaymentOptions = () => dispatch => {
  dispatch({
    type: RESET_PAYMENT_OPTION_DATA,
    payload: {}
  })
}
export const acknowledgeSuccessMessage = () => dispatch => {}
export const acknowledgeErrorMessage = () => dispatch => {}
