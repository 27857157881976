import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { Grid, Tabs, Tab, Typography, Box } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import AccountUpdateActions from '../AccountUpdate/AccountUpdateActions'
import AccountUpdateContent from '../AccountUpdate/AccountUpdateContent'
import { getFilterSelector } from '../../elements/FilterSelector/getFilterSelector'
import AssignEventsDialog from '../../Dialogs/AssignEvents/AssignEvents'
import PaymentCardList from './PaymentCardList'
import SuitesList from './SuitesList'
import SuiteHolderForm from './SuiteHolderForm'
import styles from './Content.css'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

function BasicTabs ({ tab1Content, tab2Content, tab3Content, suiteHolderUser }) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const getUserLabel = () => {
    return !suiteHolderUser.id ? 'Add User' : 'Edit User'
  }

  return (
    <>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label={getUserLabel()} {...a11yProps(0)} />
        <Tab label="Credit Cards" {...a11yProps(1)} disabled={!suiteHolderUser.id} />
        <Tab label="Events" {...a11yProps(2)} disabled={(!suiteHolderUser.id || !suiteHolderUser.sesUser)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        {tab1Content}
      </TabPanel >
      <TabPanel value={value} index={1}>
        {tab2Content}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {tab3Content}
      </TabPanel>
    </>
  )
}

BasicTabs.propTypes = {
  suiteHolderUser: PropTypes.object,
  tab1Content: PropTypes.node,
  tab2Content: PropTypes.node,
  tab3Content: PropTypes.node
}
const EditSuiteHolderUserContent = ({
  classes,
  suiteHolderUser = {},
  companies,
  suites,
  rolesList,
  setFirstName,
  setLastName,
  setEmail,
  setContactNumber,
  setSHUserCompanies,
  setRole,
  handleSuitesUpdate,
  handleSuitesInvoiceUpdate,
  setSESUser,
  paymentDetails,
  handleCardDelete,
  cardDeletionErr,
  companyId,
  cardPresentToAdd,
  dialogActions
}) => {
  const { roleId, id: suiteHolderUserId, sesUser } = suiteHolderUser
  const [suiteId, setSuiteId] = useState(null)
  const [companyAssignment, setCompanyAssignment] = useState(false)
  const [isAssignEventsDialogOpen, setIsAssignEventsDialogOpen] = useState(false)
  const handleSubmit = () => { dialogActions?.props?.handleCancel() }
  const [company] = companies
  const sesCompany = company.name === 'SES CUSTOMER'
  const sesRolesList = sesCompany ? rolesList.filter((item) => item.name !== 'Suite holder admin') : rolesList

  const openDialog = (suiteId, companiesSuiteId) => {
    setSuiteId(suiteId)
    setCompanyAssignment(false)
    setIsAssignEventsDialogOpen(true)
  }

  const getUserFullname = `${suiteHolderUser?.firstName?.toUpperCase()} ${suiteHolderUser?.lastName?.toUpperCase()}`

  const closeDialog = () => {
    setIsAssignEventsDialogOpen(false)
  }

  const serializedUser = { id: suiteHolderUser?.id, single_event_suite_user: sesUser }

  const tab1Content = <Grid container spacing={3}>
    <SuiteHolderForm classes={classes} suiteHolderUser={suiteHolderUser} setFirstName={setFirstName} setLastName={setLastName} setEmail={setEmail} setContactNumber={setContactNumber} setSESUser={setSESUser} />
    <Grid item xs={6}>
      {company && getFilterSelector('', '', companyId, setSHUserCompanies, companies, 'name', { selectorWidth: '100%' }, false, false, '', !!companyId)}
    </Grid>
    <Grid item xs={6} className={classes.inputContainer}>
      {sesRolesList && getFilterSelector('', '', roleId, setRole, sesRolesList, 'name', { selectorWidth: '100%' }, false, false, '')}
    </Grid>
    {sesCompany && <Grid item xs={6} className={classes.inputContainer}>
      <FormControlLabel style={{ width: '400px' }}
        control={<Checkbox
          checked={sesUser || false}
          value={!sesUser}
          color="default"
          onChange={setSESUser}
        />}
        label={'Single Event Suite User'}
      />
    </Grid>}
    {sesCompany ? <SuitesList classes={classes} suites={suites} handleSuitesUpdate={handleSuitesUpdate} handleSuitesInvoiceUpdate={handleSuitesInvoiceUpdate} sesCompany={sesCompany} />
      : <SuitesList classes={classes} suites={suites} handleSuitesUpdate={handleSuitesUpdate} handleSuitesInvoiceUpdate={handleSuitesInvoiceUpdate} sesCompany={sesCompany} />}
    {dialogActions}
  </Grid>

  const tab2Content = <>
    <Grid container spacing={3}>
      {
        cardDeletionErr && <AccountUpdateContent
          classes={classes}
          accountUpdateErr={cardDeletionErr}
        />
      }
      {
        cardDeletionErr && <AccountUpdateActions
          classes={classes}
          onSubmit={handleSubmit}
        />
      }
      {
        !cardPresentToAdd && !cardDeletionErr &&
        <PaymentCardList
          classes={classes}
          paymentDetails={paymentDetails}
          handleCardDelete={handleCardDelete}
          suiteHolderUserId={suiteHolderUserId}
        />
      }
    </Grid>
  </>

  const tab3Content = <>
    <Grid className={classes.inputContainer}>
      {
        company &&
        <div className={classes.detailsHeader}>
          <div className={classes.fieldContainer}>
            {
              suites?.filter(item => item.is_assigned === true).map(suite =>
                <div className={`  ${classes.suitesListClass}`} key={suite.display_name}>
                  <div >
                    <div className={classes.fieldTitle}>SUITES</div>
                    <div className={classes.fieldValue}>{suite.display_name}</div>
                  </div>
                  <div >
                    <div className={classes.fieldTitle}>SHARE TYPE</div>
                    <div className={classes.fieldValue}>{'Full'}</div>
                  </div>
                  <div className={` ${classes.actionsContainer} ${classes.assignedValueBox}`} key={suite.display_name}>
                    <Button className={classes.assignEventButton} disabled={typeof (suite.is_saved) === 'boolean' ? !suite.is_saved : false} onClick={() => openDialog(suite.suite_id, suite.companiesSuiteId)}>Assign Events</Button>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      }
      {
        isAssignEventsDialogOpen && <AssignEventsDialog
          user={serializedUser}
          isOpen={isAssignEventsDialogOpen}
          onClose={closeDialog}
          suites={suites}
          companyName={getUserFullname}
          companyAssignment={companyAssignment}
          id={suiteId}
          companyId={companyId}
        />
      }
      {dialogActions}

    </Grid>
  </>
  return (
    <div className={classes.suiteHolderUserDetailsContainer}>
      <BasicTabs suiteHolderUser={suiteHolderUser} tab1Content={tab1Content} tab2Content={tab2Content} tab3Content={tab3Content} />
    </div>
  )
}

EditSuiteHolderUserContent.propTypes = {
  companyId: PropTypes.number,
  classes: PropTypes.object,
  suiteHolderUser: PropTypes.object,
  setFirstName: PropTypes.func,
  setLastName: PropTypes.func,
  setEmail: PropTypes.func,
  setRole: PropTypes.func,
  setSHUserCompanies: PropTypes.func,
  setContactNumber: PropTypes.func,
  rolesList: PropTypes.array,
  handleSuitesUpdate: PropTypes.func,
  suites: PropTypes.array,
  setSESUser: PropTypes.func,
  companies: PropTypes.array,
  handleSuitesInvoiceUpdate: PropTypes.func,
  paymentDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  handleCardDelete: PropTypes.func,
  cardDeletionErr: PropTypes.object,
  cardPresentToAdd: PropTypes.bool,
  dialogActions: PropTypes.node
}

export default withStyles(styles)(EditSuiteHolderUserContent)
