import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Loader from '../../elements/LoadingState/LoadingState'
import styles from './FreedomPayIframe.css'

const HPC_URL = `${process.env.REACT_APP_HPC_BASE_URL}/api/v1.5/cdn/hpc_min.js`
const FreedomPayIframe = ({
  classes,
  iframeRequestInProgress,
  freedompayIframe,
  removeFreedomPayIframe,
  tokenizeCreditCard,
  suiteHolderUserId,
  showTitle = true
}) => {
  const [submitPaymentRequestInProgress, setSubmitPaymentRequestInProgress] = useState(false)
  const [nameOnCard, setNameOnCard] = useState()
  const [nameOnCardErr, setNameOnCardErr] = useState(false)

  const handleNameOnCardChange = (name) => {
    if (/^[a-z ]+$/i.test(name) || name.length === 0) {
      setNameOnCard(name)
    }
  }

  const setFrameHeight = (data) => {
    const iframe = document.getElementById('hpc--card-frame')
    iframe.height = data.height
    iframe.width = 450
  }

  const handleErrors = () => {
    removeFreedomPayIframe()
  }

  const submitPayment = async (data) => {
    if (!nameOnCard) {
      setNameOnCardErr(true)
    } else {
      setSubmitPaymentRequestInProgress(true)
      const sessionKey = document.getElementById('hpc--card-frame').src.split('?')[1].split('=')[1]
      const paymentKey = data.paymentKeys[0]
      await tokenizeCreditCard(sessionKey, paymentKey, suiteHolderUserId, nameOnCard)
      setSubmitPaymentRequestInProgress(false)
      setNameOnCard()
    }
  }

  function eventHandler (event) {
    if (event) {
      const message = event.data
      const data = message.data

      switch (message.type) {
        case 1:
          handleErrors(data)
          break
        case 2:
          setFrameHeight(data)
          break
        case 3:
          submitPayment(data)
          break
        default:
          break
      }
    }
  }

  function addHpcScript () {
    const hpcScript = document.getElementById('hpcScript')
    if (hpcScript && hpcScript.parentNode) {
      hpcScript.parentNode.removeChild(hpcScript)
      var script = document.createElement('script')

      script.type = 'text/javascript'
      script.src = HPC_URL
      script.id = 'hpcScript'

      document.body.appendChild(script)
    }
  }
  useEffect(() => {
    addHpcScript()
    return () => {
      const hpcScript = document.getElementById('hpcScript')
      if (hpcScript && hpcScript.parentNode) {
        hpcScript.parentNode.removeChild(hpcScript)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', eventHandler)

    return () => {
      window.removeEventListener('message', eventHandler)
    }
  }, [nameOnCard])

  const handleChange = (e) => { handleNameOnCardChange(e.target.value) }

  const blurHandler = () => {
    const input = document.getElementById('addCreditCardName')
    !nameOnCard ? addNameOnCardErr(input) : addNameOnCardSuccess(input)
  }

  const addNameOnCardErr = (input) => {
    input.classList.remove('successInput')
    input.classList.add('errorInput')
    setNameOnCardErr(true)
  }

  const addNameOnCardSuccess = (input) => {
    input.classList.remove('errorInput')
    input.classList.add('successInput')
    setNameOnCardErr(false)
  }

  return (
    <div>
      {
        (submitPaymentRequestInProgress || iframeRequestInProgress) &&
        <div>
          <Loader />
        </div>
      }
      {
        !iframeRequestInProgress &&
        freedompayIframe &&
        <>
          {showTitle && <div className={classes.addCreditCardHeader}>
            <div className={classes.addCreditCardHeaderLabel}>
              Add Credit Card
            </div>
            <div className={classes.addCreditCardHeaderCloseButton} onClick={() => { setNameOnCard(); removeFreedomPayIframe(); setNameOnCardErr(false) }}>
              <CloseIcon />
            </div>
          </div>}
          <div>
            <label className={classes.label}>Name on Card</label>
            <input className={classes.input}
              placeholder={'Card Holder Name'}
              id={'addCreditCardName'}
              label={''}
              value={nameOnCard}
              onChange={handleChange}
              onBlur={blurHandler}
            />
          </div>
          {nameOnCardErr && <p className={classes.p}>Name on card is incomplete</p>}
          <div dangerouslySetInnerHTML={{ __html: freedompayIframe }} />
        </>
      }
    </div>
  )
}

FreedomPayIframe.propTypes = {
  classes: PropTypes.object,
  removeFreedomPayIframe: PropTypes.func,
  freedompayIframe: PropTypes.string,
  iframeRequestInProgress: PropTypes.bool,
  tokenizeCreditCard: PropTypes.func,
  suiteHolderUserId: PropTypes.string,
  showTitle: PropTypes.bool
}

export default withStyles(styles)(FreedomPayIframe)
