import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '../../elements/Dialog/Dialog'
import EventsListFilter from '../../elements/EventsListFilter/EventsListFilter'
import ListPicker from '../../elements/ListPicker/ListPicker'
import { fetchEventsForCompanySuite, updateCompanySuiteEvent } from '../../store/companies/actions'
import isEmpty from '../../store/validation/is-empty'
import AssignEventsTitle from './AssignEventsTitle'
import AssignEventsActions from './AssignEventsActions'
import AssignEventsHeaderText from './AssignEventsHeaderText'
import styles from './AssignEvents.css'

const AssignEvents = ({
  isOpen,
  onClose,
  classes,
  suites,
  availableEvents,
  assignedEvents,
  companyName,
  companyAssignment,
  id,
  companyId,
  fetchEventsForCompanySuite,
  updateCompanySuiteEvent,
  user = {}
}) => {
  const selectedSuite = suites && suites.find(suite => !companyAssignment ? suite.suite_id === id : suite.suiteId === id)

  const [assignments, changeAssignments] = useState({
    assignedEvents,
    availableEvents
  })
  const [search, changeSearch] = useState('')
  const updateAssignments = ({ availableItems, selectedItems }) => {
    changeAssignments({
      assignedEvents: selectedItems,
      availableEvents: availableItems
    })
  }

  useEffect(() => {
    if (id && companyId) {
      fetchEventsForCompanySuite(id, companyId, user)
    }
  }, [id, companyId, fetchEventsForCompanySuite])

  useEffect(() => {
    changeAssignments({
      assignedEvents,
      availableEvents
    })
  }, [availableEvents, assignedEvents])

  const eventsAssigned = assignments.assignedEvents || []
  const eventsAvailable = assignments.availableEvents || []

  const onSaveButtonClick = () => {
    updateCompanySuiteEvent(id, companyId, assignments, user)
    onClose()
  }

  const onAvailableItemsFilterChange = ({ category, dateFrom, dateTo, search }) => {
    changeSearch(search)
    fetchEventsForCompanySuite(id, companyId, user, {
      category,
      dateFrom,
      dateTo,
      search
    })
  }
  const suiteName = !companyAssignment ? selectedSuite.display_name : selectedSuite.suiteName

  return (
    <Dialog
      openDialog={isOpen}
      dialogTitle={<AssignEventsTitle className={classes.title} />}
      dialogContent={
        <Fragment>
          <AssignEventsHeaderText userView={!isEmpty(user)} companyName={companyName} suiteName={suiteName} isDisabled classes={classes} />
          <ListPicker
            availableItemsButtonLabel="Assign"
            availableItemsFilter={
              <EventsListFilter onChange={onAvailableItemsFilterChange} />
            }
            availableItemsFilterText={search}
            availableItemsHeadline="Available Events"
            availableItemsNameKey="web_name"
            className={classes.listPicker}
            initialSelectedItems={eventsAssigned}
            initialAvailableItems={eventsAvailable}
            onChange={updateAssignments}
            selectedItemsButtonLabel="Unassign"
            selectedItemsHeadline="Assigned Events"
            selectedItemsNameKey="web_name"
          />
        </Fragment>
      }
      dialogActions={
        <AssignEventsActions
          classes={classes}
          onCancel={onClose}
          onSave={onSaveButtonClick}
        />
      }
      classes={{
        genericPaperStyles: classes.genericPaperStyles,
        genericDialogActionRoot: classes.genericDialogActionRoot,
        genericDialogContentRoot: classes.genericDialogContentRoot,
        genericDialogTitleRoot: classes.genericDialogTitleRoot
      }}
      onDialogClose={onClose}
    />
  )
}

AssignEvents.propTypes = {
  classes: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  suites: PropTypes.array,
  availableEvents: PropTypes.array,
  assignedEvents: PropTypes.array,
  companyName: PropTypes.string,
  id: PropTypes.string,
  companyId: PropTypes.string,
  fetchEventsForCompanySuite: PropTypes.func,
  updateCompanySuiteEvent: PropTypes.func,
  companyAssignment: PropTypes.bool,
  user: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    assignedEvents: state.companiesData.companiesSuitesAssignedEvents,
    availableEvents: state.companiesData.companiesSuitesAvailableEvents
  }
}

const mapDispatchToProps = {
  fetchEventsForCompanySuite,
  updateCompanySuiteEvent
}

const AssignEventsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignEvents)

export default withStyles(styles)(AssignEventsContainer)
